import { useState } from "react";
import { 
    Radio, 
    RadioGroup,
    FormControl,
    Input,
    Select,
    Stack ,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    InputGroup,
    InputLeftElement,
    Text
} from '@chakra-ui/react'
import { createCoupon } from "../../../../services/APIService";

const durations = [
    {value: 'once', name: "Once" },
    {value: 'forever', name: "Forever"},
]

const products = [
    {value: 'membership', name: 'Membership'},
    {value: 'cosec', name: 'CoSec'},
    {value: 'both', name: 'Both'}
]

const countries = [
    {value: 'AU', name: "Australia" },
    {value: 'UK', name: "UK" },
]

const locale : {[key: string]: any} = {
    AU: {
        currency: 'AUD',
        symbol: '$'
    },
    UK: {
        currency: 'GBP',
        symbol: '£'
    }
}

interface IDiscountCreationProps {
    addRow: Function,
    open: boolean,
    onClose: React.MouseEventHandler<HTMLButtonElement>,
}


export default function DiscountCreationDialog(props : IDiscountCreationProps) : JSX.Element{
    const { addRow, open, onClose} = props;
    
    const [values, setValues] = useState({
        couponName: '',
        couponId: '',
        product: 'membership',
        type: 'amount',
        duration: 'once',
        price: '0.00',
        originalPrice: '',
        amount: '',
        country: 'AU'
    });

    const [price, setPrice] = useState(0)

    const handleChange = (prop : string) => (event : React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleSelectChange = (prop : string) => (event : React.ChangeEvent<HTMLSelectElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    }

    const handleTypeChange = (nextValue: string) => {
        setValues({ ...values, type: nextValue });
    }

    const handlePriceChange = (prop: string) => (event : React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });


        if(values.amount !== '' && values.originalPrice !== '') {

            if(values.type === "percent") {
                if(prop === "amount") {
                    let p = Number(values.originalPrice) - Number(values.originalPrice) * (Number(event.target.value) /100);
                    setPrice(p)
                } else {
                    let p = Number(event.target.value) - Number(event.target.value) * (Number(values.amount) /100);
                    setPrice(p)
                }

            } 
            else if(values.type === "amount"){
                if(prop === "amount") {
                    let p = Number(values.originalPrice) - Number(event.target.value);
                    setPrice(p)
                } else {
                    let p = Number(event.target.value) - Number(values.amount);
                    setPrice(p)
                }
            }
        }
    }


    const handleSubmit = async (event :  React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        let obj;

        
        if(values.type === "percent"){
            obj = {
                name: values.couponName,
                id: values.couponId,
                percent_off: values.amount,
                duration: values.duration,
                currency: values.country === 'AU' ? 'AUD' : 'GBP'
            }
        } else {
            obj = {
                name: values.couponName,
                id: values.couponId,
                amount_off: Number(values.amount) * 100,
                duration: values.duration,
                currency: values.country === 'AU' ? 'AUD' : 'GBP'
            }
        }

        await createCoupon(obj, {
            product: values.product, 
            price: price,
            country: values.country
        });
  

        addRow(values.couponName, values.couponId, values.product, price, values.country);
        
    }

    return (    
        <Modal isOpen={open} onClose={() => console.log("")}>
            <ModalOverlay />
            <ModalContent>
            <ModalHeader>Add a new Coupon</ModalHeader>
            <ModalCloseButton onClick={onClose}/>
            <ModalBody>

                    <FormControl>
                        <Stack spacing={3}>
                            
                            <Text >Coupon Name</Text>
                            <Input
                                placeholder="Coupon Name" 
                                name="coupon_name" 
                                onChange={handleChange("couponName")} 
                                value={values.couponName}
                                size='md'
                                variant='outline'
                            />

                            <Text >Coupon ID</Text>
                            <Input 
                                placeholder="Coupon ID"
                                name="coupon_id" 
                                onChange={handleChange("couponId")}  
                                value={values.couponId}
                                size='md'
                                variant='outline'
                                />

                            <Text>Product</Text>
                            <Select
                                placeholder="Product"
                                value={values.product}
                                name="product"
                                onChange={handleSelectChange("product")}
                                
                            >
                                {products.map(item => (
                                    <option value={item.value} key={item.value}>{item.name}</option >
                                ))}
                            </Select>

                            <Text >Country</Text>
                            <Select
                                placeholder="Country"
                                value={values.country}
                                name="product"
                                onChange={handleSelectChange("country")}
                                
                            >
                                {countries.map(item => (
                                    <option value={item.value} key={item.value}>{item.name}</option>
                                ))}
                            </Select>

                            <Text>Type</Text>
                            <RadioGroup
                                defaultValue="amount"
                                name="type"
                                onChange={handleTypeChange}
                            >
                                <Stack>
                                    <Radio  value="amount" >Amount</Radio>
                                    <Radio  value="percent">Percent</Radio>
                                </Stack>
                            </RadioGroup>

                            <Text>Original Price (Before Tax)</Text>
                            <InputGroup>

                                <InputLeftElement
                                    pointerEvents='none'
                                    children={locale[values.country].symbol}
                                />
                                <Input
                                    value={values.originalPrice}
                                    name="originalPrice"
                                    onChange={handlePriceChange("originalPrice")}
                                    size='md'
                                    variant='outline'
                                />
                            </InputGroup>

                            <Text>{values.type === "percent" ? 'Discount Percentage' : 'Discount Amount (Before Tax)'}</Text>
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents='none'
                                    children={values.type === "percent" ? '%' : locale[values.country].symbol}
                                />
                                <Input
                                    value={values.amount}
                                    onChange={handlePriceChange("amount")}
                                    name="amount"
                                    size='md'
                                    variant='outline'
                                />
                            </InputGroup>

                            <Text>Price after discount (Before Tax)</Text>
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents='none'
                                    children={locale[values.country].symbol}
                                />
                                <Input
                                    readOnly
                                    value={price}
                                    size='md'
                                    variant='outline'
                                    name="price"
                                />
                            </InputGroup>

                            <Text>Duration</Text>
                            <Select
                                placeholder="Duration"
                                value={values.duration}
                                name="duration"
                                onChange={handleSelectChange("duration")}
                                
                            >
                                {durations.map(item => (
                                    <option value={item.value} key={item.value}>{item.name}</option>
                                ))}
                            </Select>
                        </Stack>
                    </FormControl>

            </ModalBody>

                <ModalFooter>
                    <Button  variant='ghost' mr={3} onClick={onClose}>
                        Close
                    </Button>
                    <Button colorScheme='blue' onClick={handleSubmit}>Submit</Button>
                </ModalFooter>
            </ModalContent>
      </Modal>
    )
}