/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { 
    Box, 
    FormControl, 
    Input, 
    SimpleGrid, 
    Stack, 
    Text, 
    Button,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Checkbox,
    Select,
    Tooltip,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    useColorModeValue,
 } from "@chakra-ui/react";



import { Autocomplete, SelectItemProps, Loader, AutocompleteItem, CheckIcon, Modal, createStyles, SegmentedControl, Title, Select as MantineSelect } from '@mantine/core';

import {forwardRef, useEffect, useMemo, useState} from "react";

import { API } from 'aws-amplify';

import * as queries from 'graphql/queries';
import * as mutations from 'graphql/mutations';
import { ProductGetResult, ProductListResult, ProposalGetResult } from "proposals";

import PackageTable from "./components/PackageTable";
import { packageColumns } from "./variables/packageTableColumns";
import GovFeesTable from "./components/GovFeesTable";
import { govFeeColumns } from "./variables/govFeeColumns";
import { industry as IndustryData} from "./variables/industry";

import 'react-quill/dist/quill.snow.css';
import RTE from "./components/RTE";

import { ProposalProduct, UpdateSLProposalsInput } from "API";
import { ICoupon } from "coupons";
import { getCoupons, getLawyers } from "services/APIService";
import { useParams, useHistory  } from "react-router-dom";

import styled from "styled-components";

import { Paper as Section } from '@mantine/core';

import { DealProductListResult } from "pipedrive";
import axios from "axios";
import { useAuth } from "hooks/AuthContext";
import { debounce } from "lodash";


const SectionHeader = styled(Title)`
    width: 80vw;

    font-family: 'DM Sans', sans-serif;
    font-size: 1.8rem;
`;

const HeaderContainer = styled.div`
    display: flex;
    margin: 0 0 20px 0;
`

const TotalPrice = styled(Text)`
`

const MembershipPrice = styled(Text)`
`

const HelperText = styled(Text)`
    font-size: 0.8rem;
    margin: 0 0 0 10px;
    color: #6b6b6b;
`

const HelperLink = styled.a`
    color: #23c0b9;
    text-decoration: underline;
`

const FieldHeader = styled(Text)`
    font-size: 1.0rem;
    font-weight: 600;
`

const Required = styled.span`
    color: red;
    content: "*";
    
`

var au_discounts : ICoupon[] = [];
var uk_discounts : ICoupon[] = [];

var lawyers : any[] = [];

const useStyles = createStyles((theme) => ({
    root: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
      boxShadow: theme.shadows.md,
      border: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[1]
      }`,
      width: "200px",
      float: "right",
    },
  
    active: {
      backgroundImage: theme.fn.gradient({ from: 'green', to: 'blue' }),
    },
  
    control: {
      border: '0 !important',
    },
  
    labelActive: {
      color: `${theme.white} !important`,
    },
  }));

type ProposalParams = {
    id: string;
}

const INTRO_DEFAULT_TEXT : string = `
    <p> We're looking forward to working together! Please see our proposal for your legal project below. </p>
`

interface ProductParam {
    SKU: string;
    discount: string;
    price: string;
    optional: boolean;
    gst_free: boolean;
    description: string;
    name: string;
    link: string;
    pipedrive: string
    summary?: string;
}

export default function ProposalEditor() {
    const { classes } = useStyles();
    const {id } = useParams<ProposalParams>();
    const history = useHistory();
    const { user } = useAuth();
    
    
    const [products, setProducts] = useState<Array<any>>([]);
    const [govFees, setGovFees] = useState<Array<any>>([]);
    const [fetching, setFetching] = useState(false);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [searching, setSearching] = useState(false);
    const [searchResults, setSearchResults] = useState<Array<any>>([]);
    const [creating, setCreating] = useState(false);

    const [created, setCreated] = useState(false);
    const [toolTip, setToolTip] = useState(false);

    const [deleteModal, setDeleteModal] = useState(false);

    const [express, setExpress] = useState(false);

    let exp = new Date();
    exp.setDate(exp.getDate() + 30);

	const textColor = useColorModeValue('secondaryGray.900', 'white');

    const [values, setValues] = useState<UpdateSLProposalsInput>({
        id: '',
        deal_id: '',
        client_name: '',
        client_number: '',
        company_name: '',
        company_number: '',
        proposal_brief: INTRO_DEFAULT_TEXT,
        delivery_date: 8,
        expiryDate: exp.toISOString().substring(0, 16),
        additional_notes: '',
        membership_price: 99,
        membership_discount_percentage: 10,
        show_member_upsell: true,
        add_membership: true,
        membership_discount: "",
        already_member: false,
        country: 'AU',
        searchquery: '',
        status: "ACTIVE",
        preselected_packages: true,
        conflict_search: false,
        express_delivery_date: 0,
        email: '',
        phone: '',
        lawyer_notes: '',
        industry: '',
        manual_allocation: false,
        specific_lawyer: '',
        lpm_notes: '',
        project_name: '',
    });

    let should_member_discount : number = (values.add_membership || values.already_member || values.member_upsell) ? values.membership_discount_percentage : 0;
    let currency = values.country == 'AU' ? '$' : '£';
    const [errors, setErrors] = useState({
        button: false,
        multiple_govs: false,
        deal: false,
        client_name: false,
        delivery_date: false,
        expiryDate: false,
        member_discount: false,
        packages: false,
        company_name: false,
        proposal_brief: false,
        linked_gov: false,
        conflict_search: false,
        express_delivery_date: false,
        industry: false,
        project_name: false,
        lawyer_notes: false,
    });
    


    useEffect(() => {

        async function fetchProposal(){
            let skus : ProductParam[] = [];
            try {
                setFetching(true);
                const proposal = await (API.graphql({ query: queries.getSLProposals, variables: { id: id } }) as Promise<ProposalGetResult>) ;
                let proposal_res = proposal.data.getSLProposals;
                console.log(proposal_res)
                for(let i = 0; i < proposal_res.products.length; i++){

                    if(proposal_res.products[i].SKU === "Z0003" || proposal_res.products[i].SKU === "EWZ0003"){
                        setExpress(true)
                    }

                    skus.push({
                        SKU : proposal_res.products[i].SKU,
                        discount : proposal_res.products[i].discount,
                        price : proposal_res.products[i].price,
                        optional : proposal_res.products[i].optional,
                        gst_free : proposal_res.products[i].gst_free,
                        description : proposal_res.products[i].description,
                        name : proposal_res.products[i].name,
                        link: proposal_res.products[i].link,
                        pipedrive: proposal_res.products[i].pipedrive,
                        summary: proposal_res.products[i].summary
                    });
                }

                if(proposal_res.status !== "PAID") proposal_res.status = "ACTIVE"

                setValues(proposal_res)
                addProductsFromSKU(skus)
                setFetching(false);
                console.log(values)
            } catch (err) {
                console.log(err);
            }

            console.log(user)
        }


        async function fetchCoupons(){
            setLoading(true);
            uk_discounts = [];
            au_discounts = [];
            
            //console.log(query.get("deal"))
            let c = await getCoupons(); 
            for(let i = 0; i < c.Items.length; i++){
                let item = c.Items[i];
                switch(item.country){
                    case 'AU': 
                        au_discounts.push({api: item.coupon_id, name: item.coupon_name, type: item.coupon_type, price: item.coupon_value, country: item.country});  
                        break;
                    case 'UK':
                        uk_discounts.push({api: item.coupon_id, name: item.coupon_name, type: item.coupon_type, price: item.coupon_value, country: item.country}); 
                        break;
                }

            }

            
            let l = await getLawyers();
            for(let i = 0; i < l.length; i++){
                let item = l[i];

                lawyers.push({
                    value: item['Staff Name'],
                    label: item['Staff Name'],
                });

            }

            setLoading(false);

            //console.log(au_discounts);
        }

        async function resetValues(){
            setValues({
                id: '',
                deal_id: '',
                client_name: '',
                client_number: '',
                company_name: '',
                company_number: '',
                proposal_brief: INTRO_DEFAULT_TEXT,
                delivery_date: 8,
                expiryDate: exp.toISOString().substring(0, 16),
                additional_notes: '',
                membership_price: 99,
                membership_discount_percentage: 10,
                show_member_upsell: true,
                add_membership: true,
                membership_discount: "",
                already_member: false,
                country: 'AU',
                searchquery: '',
            });
        }

        /*
        async function updateStatusToEdit(){
            if(values.status !== "EDIT" && values.status !== "PAID"){
                try {
                    await API.graphql({ query: mutations.updateSLProposals, variables: { input: { id: id, status: "EDIT" } } });
                } catch (err) {
                    console.log(err);
                }
            }

        }
        */

        resetValues();
        fetchCoupons();
        fetchProposal();
        //updateStatusToEdit();
      }, []);

    const handleChange = (prop : string) => (event : React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleProductChange = (prop : string, index : number, value : any) => {
        let temp = [...products];
        temp[index][prop] = value;
        setProducts(temp);
    };

    const handleGovFeeChange = (prop : string, index : number, value : any) => {
        let temp = [...govFees];
        temp[index][prop] = value;
        setGovFees(temp);
    };

    async function addProductsFromSKU(products: ProductParam[]){
        let temp: any[] = [] 
        setProducts([]);
        setGovFees([]);

        await Promise.all(products.map(async (product) => {

            /*
                const package_res = await (API.graphql({
                    query: queries.getSLPackages, 
                    variables : { SKU : id.SKU }
                }) as Promise<PackageGetResult>)
 */
                //let package_data = package_res.data.getSLPackages;

                

                if(product.SKU === "Z0003" || product.SKU === "EWZ0003"){
                    setExpress(true)
                }

                temp.push({
                    SKU: product.SKU,
                    package_name: product.name,
                    price: product.price.toString(),
                    scope: product.description,
                    discount: product.discount,
                    link: product.link,
                    gst_free: product.gst_free,
                    optional: product.optional,
                    pipedrive: product.pipedrive
                })
        }))
        console.log(temp)
        addProducts(temp, true);
    }

    function setPackageDiscount(value : string, index: number){
        let temp = [...products];
        if(value !== ""){
            if(parseFloat(value) < temp[index].base_price){
                temp[index].discount = value;
                temp[index].price = temp[index].base_price - parseFloat(temp[index].discount);
                setProducts(temp);
            } else {
                temp[index].discount = temp[index].base_price;
                temp[index].price = temp[index].base_price - temp[index].base_price;
                setProducts(temp);
            }

        } else {
            temp[index].discount = 0;
            temp[index].price = temp[index].base_price;
            setProducts(temp);
        }
    }
    
    function setPackageBasePrice(value : string, index: number){
        let temp = [...products];
        if(value !== ""){
            if(parseFloat(value) > 0){
                temp[index].base_price = value;
                temp[index].price = parseFloat(value) - parseFloat(temp[index].discount);
                setProducts(temp);
            } else {
                temp[index].base_price = 0;
                temp[index].price = 0;
                setProducts(temp);
            }

        } else {
            temp[index].base_price = 0;
            temp[index].price = 0;
            setProducts(temp);
        }
    }


    async function searchPackages(value : string) {
        setSearching(true)
        setSearchResults([]);


        if(value !== ""){
            let filter = {
                and: [{
                    searchquery : { contains: value.toLowerCase() }
                }, {
                    country: { contains: values.country }
                }]
            }

            let res : any[] = [];

            const search = await (API.graphql({
                query: queries.listSLProducts, 
                variables : { filter: filter , limit: 1000}
            }) as Promise<ProductListResult>);

            let items = search.data.listSLProducts.items;

            res = items.map((item) => ({...item, value: item.package_name}));
            //console.log(res)
            setSearchResults(res);

            

        }

        setSearching(false);

    }
    
    const debouncedSearch = debounce(searchPackages, 500);
    
    async function getGovermentPackage(id : string, link_package: string){
        const package_res = await (API.graphql({
            query: queries.getSLProducts, 
            variables : { SKU : id }
        }) as Promise<ProductGetResult>);

        let item = package_res.data.getSLProducts;

        return {
            sku: item.SKU,
            name: item.package_name,
            price: parseFloat(item.price),
            scope: item.scope,
            link: link_package,
            gst_free: true,
            pipedrive: item.pipedrive
        }


    }

    const handleDiscount = (event : React.ChangeEvent<HTMLSelectElement>) => {

        let discounts = values.country === "AU" ? au_discounts : uk_discounts;
        let discount = discounts.find((item) => item.api === event.target.value);
  
        setValues({ ...values, membership_discount: event.target.value, membership_price: parseFloat(discount.price) });
    };
    

    const handleDelete = async () => {

        setLoading(true)
        const deleteProposal = await API.graphql({ query: mutations.deleteSLProposals, variables: {input: {id: values.id}}});
        console.log(deleteProposal)
        setDeleteModal(false);
        setLoading(false);

        history.push("/admin/proposals");
    }


    async function addProducts(items: any[], fromProposal: boolean = false){
        let temp = [...products];
        let temp_gov = [...govFees];
        await Promise.all(items.map(async (item) => {
            if(item.SKU.includes("G")){
                temp_gov.push({
                    sku: item.SKU,
                    name: item.package_name,
                    price: parseFloat(item.price),
                    scope: `
                    ${item.summary !== null && item.summary !== undefined ? "<b>WHAT'S IT FOR?</b><br/>" : ""}${item.summary !== null && item.summary !== undefined ? item.summary+"<br/><br/>": ""}
                    ${!fromProposal ? "<b>WHAT'S INCLUDED?</b>" :""}
                    ${item.scope}
                    `,
                    link: item.link ? item.link : "",
                    gst_free: true,
                    pipedrive: item.pipedrive
                });
            } else {
                if(item.SKU === "Z0003" || item.SKU === "EWZ0003"){
                    setExpress(true)
                }
    
                temp.push({
                    sku: item.SKU,
                    name: item.package_name ? item.package_name : item.name,
                    price: parseFloat(item.price.replace(/,/g, '')),
                    scope: `
                    ${item.summary !== null && item.summary !== undefined ? "<b>WHAT'S IT FOR?</b><br/>" : ""}${item.summary !== null && item.summary !== undefined ? item.summary+"<br/><br/>": ""}
                    ${!fromProposal ? "<b>WHAT'S INCLUDED?</b>" :""}
                    ${item.scope}
                    `,
                    discount: item.discount ? item.discount : 0,
                    base_price: parseFloat(item.price.replace(/,/g, '')),
                    optional: item.optional ? item.optional : false,
                    gst_free: item.gst_free ? item.gst_free : false,
                    pipedrive: item.pipedrive
                })

                
                if(item.gov_fees !== null && !fromProposal){
                    let fees = item.gov_fees.split(",");

                    await Promise.all(fees.map(async (fee : string) => {
                        console.log(fee)
                        let fee_res = await getGovermentPackage(fee.trim(), item.package_name);
                        console.log(fee_res)
                        temp_gov.push(fee_res);
                    }))

                    if(fees.length > 1){
                        setErrors({ ...errors, multiple_govs: true });
                    } 
                }
    
            }
        }))

        console.log(temp)
        setProducts(temp);
        setGovFees(temp_gov);
    }

    async function onPackageSelect(item: AutocompleteItem) {
        setSearch("");
        addProducts([item]);
    }

    function removePackage(index : number) {

        let product = products.find((item, i) => i === index);

        if(product.sku === "Z0003" || product.sku === "EWZ0003"){
            setExpress(false)
        }

        setProducts(prevProducts => prevProducts.filter((item, i) => i !== index));
    }

    function removeGovFees(index : number) {


        setGovFees(prevFees => prevFees.filter((item, i) => i !== index));
    }

    const project_title : string = useMemo(() => {
        let title = "";

        if(products.length > 0) {
            if(products.length === 1){
                title = products[0].name;
            }
            else if(products.length === 2){
                title = products[0].name + " & " + products[1].name;
            }
            else {
                title = products[0].name + ", " + products[1].name + " & more";
            }
        }


        return title; 
    }, [products, govFees, values]);


    const member_discount_value : number = useMemo(() => {
        let total = 0;

        if(values.already_member || values.add_membership || values.member_upsell){
            let mem_disc = values.membership_discount_percentage / 100;
            
            products.forEach((item) => {

                total += (parseFloat(item.base_price)) * mem_disc;   
            })
    
        }

        return total; 
    }, [products, govFees, values]);

    const member_discount_value_not_optional : number = useMemo(() => {
        let total = 0;

        if(values.already_member || values.add_membership || values.member_upsell){
            let mem_disc = values.membership_discount_percentage / 100;

            products.forEach((item) => {

                if(!item.optional) total += (parseFloat(item.base_price)) * mem_disc;

            })
    
        }

        return total; 
    }, [products, govFees, values]);



    const value_tax_free : number = useMemo(() => {
        let total = 0;

        products.forEach((item) => {

                total += parseFloat(item.base_price);
            
        })


        govFees.forEach((item) => {
            total += parseFloat(item.price);
        })

        return total; 
    }, [products, govFees, values]);

    function getPrice(item : any) : number{
        let price : number = parseFloat(item.base_price) - parseFloat(item.discount);

        if(values.already_member || values.add_membership || values.member_upsell){
            let mem_disc = values.membership_discount_percentage / 100;

            price -= price * mem_disc;
        }

        return price;
    }


    const tax : number = useMemo(() => {
        let total = 0;
        let tax_val : number = values.country === "AU" ? 0.1 : 0.2;

        products.forEach((item) => {
            if(!item.gst_free && !item.government_fee){

                    total += getPrice(item);


            }
        })

        if(values.add_membership){
            total += values.membership_price;
        }

        return total * tax_val;

    }, [products, govFees, values]);  

    const discount : number = useMemo(() => {
        let total = 0;


        let mem_disc = values.membership_discount_percentage / 100;

        products.forEach((item) => {

                if(values.already_member || values.add_membership){
                    total += (parseFloat(item.base_price) * mem_disc) + parseFloat(item.discount);
                }
                else {
                    total += parseFloat(item.discount);
                }

            
        })

        return total; 
    }, [products, govFees, values]);  

    function formatProducts() : ProposalProduct[] {
        let temp : ProposalProduct[] = [];

        for(let i = 0; i < products.length; i++){
            temp.push({
                name: products[i].name,
                price: products[i].base_price,
                optional: products[i].optional,
                description: products[i].scope,
                discount: products[i].discount,
                pipedrive: products[i].pipedrive,
                SKU: products[i].sku,
                gst_free: products[i].gst_free,
            })
        }

        if(govFees.length > 0){
            for(let i = 0; i < govFees.length; i++){
                temp.push({
                    name: govFees[i].name,
                    price: govFees[i].price,
                    optional: govFees[i].optional,
                    description: govFees[i].scope,
                    pipedrive: products[i].pipedrive,
                    government_fee: true,
                    SKU: govFees[i].sku,
                    link: govFees[i].link,
                })
            }
        }


        return temp;
    }

    const value : number = useMemo(() => {
        let total = value_tax_free;

        if( values.add_membership){
            total += values.membership_price;
        }

        total -= discount;
        
        total += tax;

        return total; 
    }, [products, govFees, values]);

    async function validate() : Promise<boolean> {
        let valid = true;

        setErrors({
            button: false,
            client_name: false,
            delivery_date: false,
            expiryDate: false,
            member_discount: false,
            deal: false,
            multiple_govs: false,
            packages: false,
            company_name: false,
            proposal_brief: false,
            linked_gov: false,
            conflict_search: false,
            express_delivery_date: false,
            industry: false,
            lawyer_notes: false,
            project_name: false
        })

        let temp = {...errors};

        if(values.delivery_date === 0 || values.delivery_date === null){
            temp.delivery_date = true;
            valid = false;
        }

        if(values.expiryDate === "" || values.expiryDate === null){
            temp.expiryDate = true;
            valid = false;
        }

        if((values.add_membership === true || values.show_member_upsell) && values.membership_discount === ""){
            if(!values.already_member){
                temp.member_discount = true;
                valid = false;
            }
        }

        if(products.length === 0){
            temp.packages = true;
            valid = false;
        }

        if(values.deal_id === "" || values.deal_id === undefined){
            temp.deal = true;
            valid = false;
        }

        if(values.client_name === "" || values.client_name === null){
            temp.client_name = true;
            valid = false;
        }

        if(ReturnBiz && (values.company_name === "" || values.company_name === null)){
            temp.company_name = true;
            valid = false;
        }


        if(values.proposal_brief === "" || values.proposal_brief === null){
            temp.proposal_brief = true;
            valid = false;
        }

        if(govFees.length >= 1){
            var govFeeArr = govFees.map(function(item){ return item.link });
            var isDuplicate = govFeeArr.some(function(item, idx){ 
                return govFeeArr.indexOf(item) != idx 
            });

            if(isDuplicate){
                temp.linked_gov = true;
                valid = false;
            }
        }

        if(values.conflict_search === false && values.country == "AU"){
            temp.conflict_search = true;
            valid = false;
        }

        if(!valid) temp.button = true;

        console.log(temp)
        await setErrors(temp);

        setTimeout(function(){
            return setErrors({
                ...temp,
                button: false,
            });
    
        }, 5000);

        return valid;
    }

    function formatPipedriveProducts(){
        let temp = [];

        for(let i = 0; i < products.length; i++){
            temp.push({
                product_id: parseInt(products[i].pipedrive),
                item_price: products[i].price,
                quantity: 1,
            })
        }

        if(govFees.length > 0){
            for(let i = 0; i < govFees.length; i++){
                temp.push({
                    product_id: parseInt(govFees[i].pipedrive),
                    item_price: govFees[i].price,
                    quantity: 1,
                })
            }
        }

        return temp;
    }


    async function deleteProductsPipedrive(proposal : UpdateSLProposalsInput){
        try {
            //GET PRODUCTS FROM PIPEDRIVE DEAL
            let deal_products : DealProductListResult = await axios.get(`https://api.pipedrive.com/v1/deals/${proposal.deal_id}/products?api_token=be6a00210d66db26c38a49ee86774bf098c9c87e`)
            console.log(deal_products)

            if(deal_products.data.data !== null){
                //DELETE ALL PRODUCTS FROM PIPEDRIVE DEAL
                for(let i = 0; i < deal_products.data.data.length; i++){
                    await axios.delete(`https://api.pipedrive.com/v1/deals/${proposal.deal_id}/products/${ deal_products.data.data[i].id}?api_token=be6a00210d66db26c38a49ee86774bf098c9c87e`)
                }
            }

        } catch (e) {
            console.error(e)
        }
    }

    async function updatePipedrive(proposal : UpdateSLProposalsInput){

        try {

            let pipedrive_products = await formatPipedriveProducts();
            console.log(pipedrive_products)
            //ADD ALL PRODUCTS TO PIPEDRIVE DEAL
            for(let i = 0; i < pipedrive_products.length; i++){
                await axios.post(`https://api.pipedrive.com/v1/deals/${proposal.deal_id}/products?api_token=be6a00210d66db26c38a49ee86774bf098c9c87e`, pipedrive_products[i])
            }

            //VIEW 39921c23d751f6ca20f5f04f43275315041d7c0c
            //EDIT 9febaf4fe9c856bf360a3f5ba96f28ddd814fba0
            
            await axios.put(`https://api.pipedrive.com/v1/deals/${proposal.deal_id}?api_token=be6a00210d66db26c38a49ee86774bf098c9c87e`, {
                "39921c23d751f6ca20f5f04f43275315041d7c0c" : "https://proposal.sprintlaw.co/" + proposal.id,
                "9febaf4fe9c856bf360a3f5ba96f28ddd814fba0" : "https://admin.sprintlaw.co/#/admin/proposals/edit/" + proposal.id,
            })
            

        } catch (e) {
            console.error(e)
        }

    }
    

    async function createProposal(){
        setCreating(true);

        console.log(values)
        if(await validate()){
            let current_date = new Date();

            let search = 
            (values.client_name ? values.client_name.toLowerCase() : "") + " " + 
            (values.client_number ? values.client_number.toLowerCase() : "" ) + " " + 
            (values.company_name ? values.company_name.toLowerCase() : "") + " " +
            (values.deal_id ? values.deal_id.toLowerCase() : "") + " " +
            user.attributes.name + " " +
            (values.id ? values.id.toLowerCase() : "");
            
            let proposal : UpdateSLProposalsInput = {
                id: values.id,
                client_name: values.client_name,
                client_number: values.client_number,
                company_name: values.company_name,
                company_number: values.company_number,
                additional_notes: values.additional_notes,
                country: values.country,
                expiryDate: values.expiryDate,
                deal_id: values.deal_id,
                delivery_date: values.delivery_date,
                proposal_brief: values.proposal_brief,
                status: values.status,
                products: formatProducts(),
                membership_discount: values.membership_discount,
                membership_price: values.membership_discount !== "" ? values.membership_price : 0,
                membership_discount_percentage: values.membership_discount_percentage,
                show_member_upsell: values.show_member_upsell,
                add_membership: values.add_membership,
                already_member: values.already_member,
                value: parseFloat(value.toFixed(2)),
                searchquery: search,
                preselected_packages: values.preselected_packages,
                conflict_search: values.conflict_search,
                creator: user.attributes.name,
                express_delivery_date: values.express_delivery_date,
                phone: values.phone,
                email: values.email,
                specific_lawyer: values.specific_lawyer,
                manual_allocation: values.manual_allocation,
                lawyer_notes: values.lawyer_notes,
                lpm_notes: values.lpm_notes,
                project_name: values.project_name,
                industry: values.industry,
            }
    
            console.log(proposal)

            let new_proposal = await API.graphql({ query: mutations.updateSLProposals, variables: {input: proposal}});
            if(values.status !== "PAID"){
                await deleteProductsPipedrive(proposal);
                await updatePipedrive(proposal);
            }

            setCreating(false)
            setCreated(true);
            console.log(new_proposal)
        } else {
            setCreating(false);
        }


    }

    const ReturnBiz : boolean = useMemo(() => {
        let temp = true;

        if(values.client_number === null || values.client_number === "") return false;

        return temp;
    }, [values])


    interface ItemProps extends SelectItemProps {
        SKU: string;
        price: string,
    }

      
    const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
        ({ SKU, price, value,  ...others }: ItemProps, ref) => 
          <div ref={ref} {...others}>
            <div>
                <Text>{SKU}</Text>
                <Text size="xs" color="dimmed">
                    {value} 
                </Text>
                <Text size="xs" color="dimmed">
                    ${price}
                </Text>
            </div>
         </div>
      );

    // Chakra Color Mode
    return fetching ? (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Loader color="cyan" size="xl" />
    </Box>) : (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <SimpleGrid
                mb='20px'
                columns={{ sm: 1, md: 1 }}
                spacing={{ base: "20px", xl: "20px" }}>

                        <FormControl>
                            <Stack spacing={7}>
                                <Section shadow="sm" p="xl" radius="md" withBorder>



                                    <Stack spacing={3}>
                                        <HeaderContainer>
                                            <SectionHeader order={1}>Settings</SectionHeader> 
                                        </HeaderContainer>

                                        {values.status !== "PAID" && 
                                        <>
                                            <FieldHeader >Status</FieldHeader>
                                            <MantineSelect
                                                placeholder="Select a Status..."
                                                value={values.status}
                                                name="status"
                                                onChange={(e) => setValues({...values, status: e})}
                                                defaultValue="ACTIVE"
                                                data={[
                                                    { label: 'ACTIVE', value: 'ACTIVE' },
                                                    { label: 'INACTIVE', value: 'INACTIVE' },
                                                    { label: 'EXPIRED', value: 'EXPIRED' },
                                                ]}
                                            />
                                            <HelperText>
                                                Use this field to change the status of the document. Setting it as inactive will take the link offline, setting it as expired will shw the user a message that the quote has expired.
                                            </HelperText>
                                        </>
                                        }

                                        {values.status === "PAID" &&
                                        <>
                                            <FieldHeader >This Proposal has already been paid please do not edit anything unless necessary.</FieldHeader>
                                        </>
                                        }


                                        <FieldHeader>Deal ID<Required>*</Required></FieldHeader>
                                        <Stack direction="row">
                                            <Input
                                                placeholder="Deal ID" 
                                                name="deal_id" 
                                                onChange={handleChange("deal_id")} 
                                                value={values.deal_id}
                                                size='md'
                                                variant='outline'
                                                isInvalid={errors.deal}
                                                errorBorderColor='red.300'
                                                disabled
                                            />
                                        </Stack>

                                        <FieldHeader>Client Name<Required>*</Required></FieldHeader>
                                        <Input
                                            placeholder="Client Name" 
                                            name="client_name" 
                                            onChange={handleChange("client_name")} 
                                            value={values.client_name}
                                            size='md'
                                            variant='outline'
                                            isInvalid={errors.client_name}
                                            errorBorderColor='red.300'

                                        />
                                        {ReturnBiz && <HelperText>Since this is a returning client please submit a contact update request form to update their details.</HelperText>}
                                        {!ReturnBiz && <HelperText>If you are unsure please leave this field blank for the client to complete.</HelperText>}

                                        <FieldHeader>Email {ReturnBiz && <Required>*</Required>}</FieldHeader>
                                        <Input
                                            placeholder="Email" 
                                            name="email" 
                                            onChange={handleChange("email")} 
                                            value={values.email}
                                            size='md'
                                            variant='outline'
                                            errorBorderColor='red.300'
                                            disabled={ReturnBiz}
                                        />
                                        {ReturnBiz && <HelperText>Since this is a returning client please submit a contact update request form to update their details.</HelperText>}
                                        {!ReturnBiz && <HelperText>If you are unsure please leave this field blank for the client to complete.</HelperText>}

                                        <FieldHeader>Company Name {ReturnBiz && <Required>*</Required>}</FieldHeader>
                                        <Input
                                            placeholder="Company Name" 
                                            name="company_name" 
                                            onChange={handleChange("company_name")} 
                                            value={values.company_name}
                                            size='md'
                                            variant='outline'
                                            errorBorderColor='red.300'
                                            disabled={ReturnBiz}
                                        />
                                        {ReturnBiz && <HelperText>Since this is a returning client please submit a contact update request form to update their details.</HelperText>}
                                        {!ReturnBiz && <HelperText>If you are unsure please leave this field blank for the client to complete.</HelperText>}

                                        <FieldHeader>{values.country === "AU" ? "ABN / ACN" : "Company Number"} </FieldHeader>
                                        <Input
                                            placeholder={values.country === "AU" ? "ABN / ACN" : "Company Number"}
                                            name="company_number" 
                                            onChange={handleChange("company_number")} 
                                            value={values.company_number}
                                            size='md'
                                            variant='outline'
                                            errorBorderColor='red.300'
                                            disabled={ReturnBiz}
                                        />
                                        {ReturnBiz && <HelperText>Since this is a returning client please submit a contact update request form to update their details.</HelperText>}
                                        {!ReturnBiz && <HelperText>If you are unsure please leave this field blank for the client to complete.</HelperText>}

                                        
                                        <FieldHeader>Client Number</FieldHeader >
                                        <Input
                                            placeholder="Client Number" 
                                            name="client_number" 
                                            onChange={handleChange("client_number")} 
                                            value={values.client_number}
                                            size='md'
                                            variant='outline'
                                            disabled
                                        />

                                        <FieldHeader>Proposal Intro <Required>*</Required></FieldHeader>
                                        <RTE value={values.proposal_brief} onChange={(value : string) =>setValues({ ...values, proposal_brief: value})} id="brief"/>
                                        <HelperText>This message will appear at the top of the Proposal. You can use this sample text or customise it.</HelperText>

                                        <FieldHeader>Estimated Delivery Date <Required>*</Required></FieldHeader>
                                        <NumberInput 
                                            defaultValue={5} 
                                            min={1} 
                                            max={100} 
                                            onChange={(value) => setValues({ ...values, delivery_date: parseInt(value)})} 
                                            value={values.delivery_date}
                                            isInvalid={errors.delivery_date}
                                            errorBorderColor='red.300'
                                        >
                                            <NumberInputField />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>

                                        {express && <>  
                                        <FieldHeader>Express Delivery Date <Required>*</Required></FieldHeader>
                                        <NumberInput 
                                            defaultValue={3} 
                                            min={1} 
                                            max={100} 
                                            onChange={(value) => setValues({ ...values, express_delivery_date: parseInt(value)})} 
                                            value={values.express_delivery_date}
                                            isInvalid={errors.express_delivery_date}
                                            errorBorderColor='red.300'
                                        >
                                            <NumberInputField />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>
                                        </>}

                                        <FieldHeader>Expiration Date <Required>*</Required></FieldHeader>
                                        <Input
                                            placeholder="Expiration Date"
                                            size="md"
                                            type="datetime-local"
                                            value={values.expiryDate}
                                            onChange={handleChange("expiryDate")} 
                                        />
                                        <HelperText>This proposal will expire in 30 days by default you can customise this by using this field. Once the document expires the client will no longer be able to pay for the quote.</HelperText>

                                        <FieldHeader>Additional Notes</FieldHeader>
                                        <RTE value={values.additional_notes} onChange={(value : string) =>setValues({ ...values, additional_notes: value})} id="notes"/>
                                        <HelperText>The content of this field will appear at the bottom of the Proposal. Use it to provide any additional information not contained in the above Proposal such as disclaimers or other limitations to scope.</HelperText>

                                        {values.country === "AU" && <>
                                            <FieldHeader>Conflict Search <Required>*</Required></FieldHeader>
                                            {!values.conflict_search && <HelperText>Please <HelperLink href={"https://sprintlaw.formstack.com/forms/conflicts?deal_id="+values.deal_id} target="_blank">complete the search</HelperLink>.</HelperText>}
                                            <Checkbox 
                                                isChecked={values.conflict_search} 
                                                onChange={(e) => setValues({...values, conflict_search: e.target.checked})} 
                                                isInvalid={errors.conflict_search}
                                            >
                                                Completed
                                            </Checkbox>
                                        </>
                                        }
                                    </Stack>
                                </Section>
                                        
                                <Section shadow="sm" p="lg" radius="md" withBorder>
                                    <Stack spacing={3}>
                                        <HeaderContainer>                                        
                                            <SectionHeader order={1}>Fixed Fee Packages</SectionHeader>
                                        </HeaderContainer>
                                        {errors.packages && <Text color='red.500' fontSize='sm'>Please add at least one package to this proposal.</Text>}

                                        <Text color={textColor} fontSize='19px' fontWeight='700' lineHeight='100%'>
                                            Select Packages
                                        </Text>
                                        <Autocomplete
                                            onChange={debouncedSearch}
                                            placeholder="Start typing to see packages"
                                            itemComponent={AutoCompleteItem}
                                            rightSection={searching ? <Loader size={16} /> : null}
                                            data={searchResults}
                                            limit={8}
                                            onItemSubmit={onPackageSelect}
                                            dropdownPosition="bottom"
                                            style={{
                                                marginBottom: '20px'
                                            }}
                                        />
                                        <HelperText>
                                            You can search for fixed fee packages or government fees using the field above.
                                        </HelperText>


                                        <PackageTable 
                                            tableData={products}
                                            columnsData={packageColumns}
                                            removePackage={removePackage}
                                            setPackageDiscount={setPackageDiscount}
                                            setPackageBasePrice={setPackageBasePrice}
                                            handleProductChange={handleProductChange}
                                            country={values.country} 
                                            member_discount={should_member_discount}
                                        />

                                        <Text color={textColor} fontSize='19px' fontWeight='700' lineHeight='100%'>
                                            Optional Packages Pre-selected?
                                        </Text>
                                        <Checkbox isChecked={values.preselected_packages} onChange={(e) => setValues({...values, preselected_packages: e.target.checked})} >Yes</Checkbox>
                                        <HelperText>
                                            By default all packages are selected on the proposal. If you would like the optional addons to be deselected untick this.
                                        </HelperText>

    
                                        <GovFeesTable 
                                            tableData={govFees} 
                                            columnsData={govFeeColumns} 
                                            products={products} 
                                            handleGovFeeChange={handleGovFeeChange} 
                                            removeGovFees={removeGovFees} 
                                            country={values.country}
                                            error={errors.linked_gov}
                                        />

                                        
                                    </Stack>
                                </Section>

                                <Section shadow="sm" p="lg" radius="md" withBorder>
                                    <Stack spacing={5}>
                                        <HeaderContainer>                                        
                                            <SectionHeader order={1}>Membership</SectionHeader>
                                        </HeaderContainer>

                                        {values.already_member && <>
                                            <FieldHeader>Membership</FieldHeader>
                                            <Text color='red.500' fontSize='sm'>Client is already a member.</Text>
                                            <HelperText>This client is already a member use this field to customise the discounnt applied to this proposal.</HelperText>
                                        </>}

                                        {!values.already_member && <>


                                            <Checkbox defaultChecked onChange={(e) => setValues({...values, show_member_upsell: e.target.checked})} >Show Membership Upsell Option</Checkbox>
                                         
                                            <Checkbox defaultChecked onChange={(e) => setValues({...values, add_membership: e.target.checked})} >Include Membership by Default</Checkbox>
                                            <HelperText>
                                                You should generally select both options above. If you do not select the first option the client will not be presented with an upsell. If you do not select the second option a membership will not automatically be added to the proposal.
                                            </HelperText>

                                            {(values.show_member_upsell || values.add_membership) &&
                                            <>
                                                <FieldHeader >Membership Promotion <Required>*</Required></FieldHeader>
                                                <Select
                                                    placeholder="Select a discount..."
                                                    value={values.membership_discount}
                                                    name="discount"
                                                    onChange={handleDiscount}
                                                    isInvalid={errors.member_discount}
                                                    errorBorderColor='red.300'
                                                >
                                                    { values.country === "AU" ? 
                                                    au_discounts.map(item => {
                                                        return item.type === "membership" || item.type === "both" ? (
                                                            <option value={item.api} key={item.api}>{item.name} - ${item.price}</option>
                                                        ) : (null)
                                                    })
                                                    :
                                                    uk_discounts.map(item => {
                                                        return item.type === "membership" || item.type === "both" ? (
                                                            <option value={item.api} key={item.api}>{item.name} - £{item.price}</option>
                                                        ) : (null)
                                                    })
                                                    }
                                                </Select>

                                                {products.length > 0 && 
                                                <HelperText>
                                                    <b style={{color: "#ED8936"}}>Suggested Promotion</b> : {member_discount_value_not_optional === 0 ? "" : "under"} {values.country === "AU" ? "$" : "£"}{member_discount_value_not_optional} ( or under {values.country === "AU" ? "$" : "£"}{member_discount_value} including optionals )
                                                </HelperText>
                                                }
                                            </>}
                                        </>}

                                        {(values.show_member_upsell || values.add_membership || values.already_member) && <>

                                        <Text >Membership Discount Amount</Text>
                                            <InputGroup>
                                                <InputLeftElement
                                                pointerEvents='none'
                                                color='gray.300'
                                                fontSize='1.2em'
                                                children='%'
                                                />
                                                <Input placeholder='Enter amount' value={values.membership_discount_percentage} onChange={handleChange("membership_discount_percentage")} />
                                            </InputGroup>
                                            <HelperText>
                                                    Customise this field to vary the membership discount on this proposal. The default is 10%.
                                            </HelperText>
                                        </>}
                                    </Stack>
                                </Section>

                                <Section shadow="sm" p="xl" radius="md" withBorder>
                                <Stack spacing={3}>
                                        <HeaderContainer>
                                            <SectionHeader order={1}>Brief to Lawyer</SectionHeader> 
                                        </HeaderContainer>

                                        <FieldHeader>Project Name <Required>*</Required></FieldHeader>
                                        <Input
                                            placeholder="Project Name" 
                                            name="project_name" 
                                            onChange={handleChange("project_name")} 
                                            value={values.project_name}
                                            size='md'
                                            variant='outline'
                                            isInvalid={errors.project_name}
                                            errorBorderColor='red.300'
                                            maxLength={49}
                                        />
                                        {products.length > 0 && 
                                                <HelperText>
                                                    <b style={{color: "#ED8936"}}>Suggested</b> : {project_title}
                                                </HelperText>
                                        }
                                        <HelperText>Please <HelperLink href={"https://www.notion.so/sprintlaw/Project-Name-Email-Title-1455983931054e3eb9c61b393fba88b1"} target="_blank">read this notion page</HelperLink>. (Max 49 Characters)</HelperText>

                                        {!ReturnBiz && values.status !== "PAID" && 
                                        <>
                                            <FieldHeader >Industry <Required>*</Required></FieldHeader>
                                            <MantineSelect
                                                placeholder="Select an Industry..."
                                                value={values.industry}
                                                name="industry"
                                                onChange={(e) => setValues({...values, industry: e})}
                                                defaultValue="ACTIVE"
                                                data={IndustryData}
                                                error={errors.industry}
                                                searchable
                                            />
                                            <HelperText>
                                                Please select the clients industry form the drop downs above. 
                                            </HelperText>
                                        </>}

                                        <FieldHeader>Lawyer Notes <Required>*</Required></FieldHeader>
                                        <RTE value={values.lawyer_notes} onChange={(value : string) =>setValues({ ...values, lawyer_notes: value})} id="lawyer-notes"/>
                                        <HelperText> Please brief the lawyer about your discussions with the client to date. Be as specific as possible, and make sure to structure your notes.</HelperText>
                                        {errors.lawyer_notes && <Text color='red.500' fontSize='sm'>Please fill in the lawyer notes for the lawyer or legal team assigned to this project.</Text>}

                                        {values.status !== "PAID" && 
                                        <>
                                            <FieldHeader >Specific Lawyer Required</FieldHeader>
                                            <MantineSelect
                                                placeholder="Select a Specific Lawyer..."
                                                value={values.specific_lawyer}
                                                name="specific_lawyer"
                                                onChange={(e) => setValues({...values, specific_lawyer: e})}
                                                defaultValue="ACTIVE"
                                                data={lawyers}
                                                searchable
                                                clearable
                                            />
                                            <HelperText>
                                                Please specify whether a specific lawyer is required to take on this project
                                            </HelperText>


                                            <FieldHeader>Manual Allocation</FieldHeader>
                                            <HelperText>Select this to skip the booking page after the proposal is accepted.</HelperText>
                                            <Checkbox 
                                                isChecked={values.manual_allocation} 
                                                onChange={(e) => setValues({...values, manual_allocation: e.target.checked})} 
                                            >
                                                Allocate Manually
                                            </Checkbox>
                                            {values.manual_allocation && 
                                            <> 
                                                <FieldHeader >LO Notes</FieldHeader>
                                                <RTE value={values.lpm_notes} onChange={(value : string) =>setValues({ ...values, lpm_notes: value})} id="lpm-notes"/>
                                                <HelperText> Please record any special notes for the Legal Operations Team or Legal Team Leaders.</HelperText>
                                            </>
                                            }
                                        </>
                                        }
                                    </Stack>
                                </Section>

                                <Section shadow="sm" p="lg" radius="md" withBorder>
                                    <Stack spacing={10}>
                                        <Stack direction="row" spacing={10}>
                                            <Stack spacing={3}>

                                                <TotalPrice>Sub Total:</TotalPrice>
                                                {(values.add_membership) && <MembershipPrice>Membership Fee:</MembershipPrice> }
                                                {(values.already_member || values.add_membership) && <MembershipPrice>Member Discount:</MembershipPrice> }
                                                <TotalPrice>Discount:</TotalPrice>
                                                <TotalPrice>{values.country === "AU" ? "GST" : "VAT"}:</TotalPrice>
                                                <TotalPrice>Grand Total:</TotalPrice>
    
                                            </Stack>
                                            <Stack spacing={3}>
                                                <TotalPrice>{currency}{value_tax_free.toFixed(2)}</TotalPrice>
                                                {(values.add_membership) && <MembershipPrice>{currency}{values.membership_price.toFixed(2)}</MembershipPrice> }
                                                {(values.already_member || values.add_membership) && <MembershipPrice>-{currency}{member_discount_value.toFixed(2)}</MembershipPrice> }
                                                <TotalPrice>-{currency}{discount.toFixed(2)}</TotalPrice>
                                                <TotalPrice>{currency}{tax.toFixed(2)}</TotalPrice>
                                                <TotalPrice>{currency}{value.toFixed(2)}</TotalPrice>
                                            </Stack>
                                        </Stack>
                                        <Button 
                                                    onClick={createProposal} 
                                                    isLoading={creating}
                                                    colorScheme={errors.button ? "red" : "teal"}
                                                    >
                                                    {errors.button ? "Please Check Fields" : "Update"}
                                        </Button>
                                        <Button 
                                                    onClick={() => window.open("https://proposal.sprintlaw.co/" + values.id, '_blank').focus()} 
                                                    isLoading={creating}
                                                    colorScheme={errors.button ? "red" : "teal"}
                                                    >
                                                    View Proposal
                                        </Button>
                                        <Button 
                                                    onClick={() => window.open("https://proposal.sprintlaw.co/" + values.id+"/brief", '_blank').focus()} 
                                                    isLoading={creating}
                                                    colorScheme={errors.button ? "red" : "teal"}
                                                    >
                                                    View Brief
                                        </Button>
                                    </Stack>
        
                                </Section>

                                <Section shadow="sm" p="lg" radius="md" withBorder>
                                    <Stack spacing={3}>
                                        <Button 
                                            onClick={() => setDeleteModal(true)} 
                                            isLoading={creating}
                                            colorScheme={"red"}
                                            >
                                            Delete
                                        </Button>
                                    </Stack>
                                </Section>
                            </Stack>
                        </FormControl>

                        {created && 
                        <div>
                            <p>Proposal successfully updated (Click to add to clipboard):</p>
                            <Tooltip
                                    label="Link added to clipboard"
                                    isOpen={toolTip}
                                >
                                <Button variant="text" onClick={()=> {
                                    navigator.clipboard.writeText("https://proposal.sprintlaw.co/" + values.id)
                                    setToolTip(true);

                                    setTimeout(() => {
                                        setToolTip(false)
                                    }, 1000);
                                }}
                                style={{
                                    margin: '5px auto',
                                    width: '100%'
                                }}>
                                    https://www.proposal.sprintlaw.co/{values.id}
                                </Button>
                            </Tooltip>
                        </div>}

            </SimpleGrid>

            <Modal
                opened={deleteModal}
                onClose={() => setDeleteModal(false)}
                title="You are about to delete this Proposal. Are you sure?"
            >


                <Stack>
                    <Button onClick={() => setDeleteModal(false)} autoFocus>NO</Button>
                <Button onClick={handleDelete}                                             colorScheme={"red"}>
                        YES
                    </Button>
                </Stack>
            </Modal>

            <Modal
                opened={errors.multiple_govs}
                onClose={() => setErrors({ ...errors, multiple_govs: false })}
                title="Warning!"
            >
                    <Text>
                        The package you have selected includes multiple attached Government Fees. Please manually select the correct Government Fee for this package in the package search field and link it to the associated package.
                    </Text>
            </Modal>
        </Box>
    );
}
