/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSLProposals = /* GraphQL */ `
  query GetSLProposals($id: String!) {
    getSLProposals(id: $id) {
      id
      client_name
      client_number
      company_name
      company_description
      company_number
      additional_notes
      country
      createdAt
      paidAt
      deal_id
      delivery_date
      expiryDate
      proposal_brief
      status
      member_upsell
      show_member_upsell
      add_membership
      membership_price
      membership_discount
      membership_discount_percentage
      already_member
      value
      searchquery
      preselected_packages
      customer_id
      products {
        name
        price
        discount
        description
        summary
        government_fee
        selected
        optional
        gst_free
        link
        SKU
        searchquery
        pipedrive
      }
      conflict_search
      short_address
      express_delivery_date
      purchased_membership
      creator
      email
      phone
      lawyer_notes
      calendly_link
      manual_allocation
      industry
      email_links
      specific_lawyer
      booked
      lpm_notes
      modifiedAt
      calendly_message
      calendly_date
      project_name
      address_line_1
      address_line_2
      city
      state
      postcode
      country_code
      formatted_address
    }
  }
`;
export const listSLProposals = /* GraphQL */ `
  query ListSLProposals(
    $filter: TableSLProposalsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSLProposals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        client_name
        client_number
        company_name
        company_description
        company_number
        additional_notes
        country
        createdAt
        paidAt
        deal_id
        delivery_date
        expiryDate
        proposal_brief
        status
        member_upsell
        show_member_upsell
        add_membership
        membership_price
        membership_discount
        membership_discount_percentage
        already_member
        value
        searchquery
        preselected_packages
        customer_id
        conflict_search
        short_address
        express_delivery_date
        purchased_membership
        creator
        email
        phone
        lawyer_notes
        calendly_link
        manual_allocation
        industry
        email_links
        specific_lawyer
        booked
        lpm_notes
        modifiedAt
        calendly_message
        calendly_date
        project_name
        address_line_1
        address_line_2
        city
        state
        postcode
        country_code
        formatted_address
      }
      nextToken
    }
  }
`;
export const getSLProducts = /* GraphQL */ `
  query GetSLProducts($SKU: String!) {
    getSLProducts(SKU: $SKU) {
      SKU
      country
      package_name
      discount
      price
      scope
      summary
      stacker
      gov_fees
      searchquery
      optional
      gst_free
      link
      pipedrive
    }
  }
`;
export const listSLProducts = /* GraphQL */ `
  query ListSLProducts(
    $filter: TableSLProductsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSLProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        SKU
        country
        package_name
        discount
        price
        scope
        summary
        stacker
        gov_fees
        searchquery
        optional
        gst_free
        link
        pipedrive
      }
      nextToken
    }
  }
`;
