import { Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue,   

	Button,
	InputGroup,
	Input,
	InputLeftElement,
	Select
} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

import { Accordion, Box } from '@mantine/core';

import { RichTextEditor } from '@mantine/rte';

// Custom components
import Card from 'components/card/Card';

export default function GovFeesTable(props: { 
	columnsData: any
	tableData: any
	products: any
	removeGovFees: (index: number) => void
	handleGovFeeChange: (prop: string, index: number, value: any) => void
	country: string
	error: boolean
}) {
	const { columnsData, tableData, products, handleGovFeeChange, removeGovFees, country, error} = props;

	const columns = useMemo(() => columnsData, [ columnsData ]);
	const data = useMemo(() => tableData, [ tableData ]);


	const tableInstance = useTable(
		{
			columns,
			data,
			initialState : { pageSize: 100 }
		},
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = tableInstance;

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
	return (
		<Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Flex justify='space-between' mb='20px' align='center'>
				<Text color={textColor} fontSize='19px' fontWeight='700' lineHeight='100%'>
					Government Fees
				</Text>

			</Flex>
			{error && <Text color='red.500' fontSize='sm' >One or more packages have multiple government fees linked.</Text>}
			<Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
				<Thead>
					{headerGroups.map((headerGroup, index) => (
						<Tr {...headerGroup.getHeaderGroupProps()} key={index}>
							{headerGroup.headers.map((column, index) => (
								<Th
									{...column.getHeaderProps(column.getSortByToggleProps())}
									pe='10px'
									key={index}
									borderColor={borderColor}>
									<Flex
										justify='space-between'
										align='center'
										fontSize={{ sm: '10px', lg: '12px' }}
										color='gray.400'>
										{column.render('Header')}
									</Flex>
								</Th>
							))}
						</Tr>
					))}

				</Thead>
				{ tableData && 
				<Tbody {...getTableBodyProps()}>
					{page.map((row, index) => {
						prepareRow(row);
						//console.log(row.values)
						return (
							<>

							<Tr {...row.getRowProps()} key={index} style={{
								borderTop: '2px solid #e2e8f0',
								borderRight: '2px solid #e2e8f0',
								borderLeft: '2px solid #e2e8f0',
							}}>
								{row.cells.map((cell, cell_index) => {
									let data;
									if (cell.column.Header === 'NAME') {
										return (
											<Td
											{...cell.getCellProps()}
											key={cell_index}
											fontSize={{ sm: '14px' }}
											minW={{ sm: '150px', md: '200px', lg: '400px' }}
											borderColor='transparent'>
											<Flex align='center'>
												<Input onChange={(event) => handleGovFeeChange("name", index, event.target.value)} value={cell.value} />
											</Flex>
											</Td>
										)
	
									} else if (cell.column.Header === 'SKU') {
										return (
											<Td
											{...cell.getCellProps()}
											key={cell_index}
											fontSize={{ sm: '14px' }}
											minW={{ sm: '150px', md: '200px', lg: 'auto' }}
											borderColor='transparent'>
											<Flex align='center'>
												<Text me='10px' color={textColor} fontSize='sm' fontWeight='700'>
													{cell.value}
												</Text>
											</Flex>
										</Td>
										)

									} else if (cell.column.Header === 'PRICE') {
										return (
											<Td
											{...cell.getCellProps()}
											key={cell_index}
											fontSize={{ sm: '14px' }}
											minW={{ sm: '150px', md: '150px', lg: '150px' }}
											borderColor='transparent'>
											<InputGroup>
												<InputLeftElement
												pointerEvents='none'
												color='gray.300'
												fontSize='1.2em'

												children={country === "AU" ? '$' : '£'}
												/>
												<Input 
													type="number" 
													onChange={(event) => handleGovFeeChange("price", index, event.target.value)} 
													onWheel={ event => event.currentTarget.blur() }
													value={cell.value} 
													maxW={{ sm: '120px', md: '120px', lg: '120px' }}
												/>
											</InputGroup>
										</Td>
										)
									} else if (cell.column.Header === 'LINK') {
										return (
											<Td
											{...cell.getCellProps()}
											key={cell_index}
											fontSize={{ sm: '14px' }}
											minW={{ sm: '350px', md: '350px', lg: '350px' }}
											borderColor='transparent'>
											<Select 
												onChange={(event) => handleGovFeeChange("link", index, event.target.value)} 
												placeholder='Select option' 
												value={cell.value}
												maxW={{ sm: '320px', md: '320px', lg: '320px' }}
											>
												{products.map((product : any, product_index : number) => {
													return (
														<option key={product.name} value={product.name}>{product.name}</option>
													)
												})}
											</Select>
										</Td>
										)
									} 
									return (
										<Td
											{...cell.getCellProps()}
											key={cell_index}
											fontSize={{ sm: '14px' }}
											minW={{ sm: '150px', md: '200px', lg: 'auto' }}
											borderColor='transparent'>
											{data}
										</Td>
									);
								})}
									<Td
											key={index}
											fontSize={{ sm: '14px' }}
											minW={{ sm: '150px', md: '200px', lg: 'auto' }}
											borderColor='transparent'>
											<Button onClick={() => removeGovFees(index)}>Delete</Button>
										</Td>

							</Tr>
							
							{row.values.scope && 
							<Tr style={{
								borderBottom: '2px solid #e2e8f0',
								borderRight: '2px solid #e2e8f0',
								borderLeft: '2px solid #e2e8f0',
							}}>
								<Td colSpan={5} key="scope">
									<Accordion>
										<Accordion.Item value={row.values.name}  
											style={{
												border: "none",
												backgroundColor: "#fafafa",
												
											}}>
		
											<Accordion.Control >
												<Box>
													<Text style={{
														padding: '10px',
														fontFamily: 'Segoe UI',
														fontWeight: 700,
														fontSize: '12px',
														color: "#a0aec0"
													}}>
														SCOPE
													</Text>
												</Box>
											</Accordion.Control>
									
											<Accordion.Panel pb={4}>
												<RichTextEditor value={row.values.scope} onChange={(value) => handleGovFeeChange("scope", index, value)} id={"row"+index} />
											</Accordion.Panel>
										</Accordion.Item>
									</Accordion>
								</Td>
							</Tr>	}
						</>
						);
					})}

				</Tbody>
				}
			</Table>
		</Card>
	);
}
