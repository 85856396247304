import RichTextEditor from '@mantine/rte';
import 'react-quill/dist/quill.snow.css';

export default function RTE(props : {
    value : string,
    onChange : (value : string) => void,
    id: string
}){
    const {value, onChange, id} = props;

    const modules = {
        toolbar: [
          [{ 'header': [1, 2, false] }],
          ['bold', 'italic', 'underline','strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ['link', 'image'],
          ['clean']
        ],
      }
    
    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
      ]

    return (
        <RichTextEditor value={value} onChange={onChange} id={id} style={{ position: 'relative', zIndex: 0, border: 0 }}/>
    )
}