/* tslint:disable */
/* eslint-disable */

export const searchSlProposals = /* GraphQL */ `
  query ListSLProposals(
    $filter: TableSLProposalsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSLProposals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        client_name
        client_number
        company_name
        createdAt
        creator
        deal_id
        status
        value
        searchquery
      }
      nextToken
    }
  }
`;