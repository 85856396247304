export const govFeeColumns = [
    {
      Header: "SKU",
      accessor: "sku",
    },
    {
      Header: "NAME",
      accessor: "name",
    },
    {
      Header: "PRICE",
      accessor: "price",
    },
    {
      Header: "LINK",
      accessor: "link",
    },
    {
      accessor: "scope",
    },
  ];