export const proposalColumns = [
    {
      Header: "NAME",
      accessor: "name",
    },
    {
      Header: "STATUS",
      accessor: "status",
    },
    {
      Header: "DATE",
      accessor: "date",
    },
    {
      Header: "VALUE",
      accessor: "value",
    },
    {
      Header: "DEAL",
      accessor: "deal",
    },
    {
      Header: "CLIENT",
      accessor: "client",
    },
    {
      Header: "CREATED BY",
      accessor: "creator",
    },
    {
      Header: "ACTIONS",
    }
  ];