/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { 
    Box, 
    FormControl, 
    Input, 
    SimpleGrid, 
    Stack, 
    Text, 
    Button,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Checkbox,
    Select,
    Tooltip,
    InputGroup,
    InputLeftElement,
    useColorModeValue,
 } from "@chakra-ui/react";

import Card from 'components/card/Card';

import { Autocomplete, SelectItemProps, Loader, AutocompleteItem, CheckIcon, Modal, createStyles, SegmentedControl, Title, Select as MantineSelect  } from '@mantine/core';

import {forwardRef, useEffect, useMemo, useState} from "react";

import { API } from 'aws-amplify';

import * as queries from 'graphql/queries';
import * as mutations from 'graphql/mutations';
import {  PackageListResult, ProductListResult, ProductGetResult } from "proposals";
import axios from 'axios';
import PackageTable from "./components/PackageTable";
import { packageColumns } from "./variables/packageTableColumns";
import GovFeesTable from "./components/GovFeesTable";
import { govFeeColumns } from "./variables/govFeeColumns";
import { industry as IndustryData} from "./variables/industry";


import 'react-quill/dist/quill.snow.css';
import RTE from "./components/RTE";

import { nanoid } from 'nanoid'
import { CreateSLProposalsInput, ProposalProduct } from "API";
import { ICoupon } from "coupons";
import { getCoupons, getLawyers, createProposal as createProposalAPI } from "services/APIService";
import { Link, useParams } from "react-router-dom";

import styled from "styled-components";

import { Paper as Section } from '@mantine/core';
import { DealProductListResult } from "pipedrive";

import { useAuth } from 'hooks/AuthContext';
import { debounce } from "lodash";

const SectionHeader = styled(Title)`
    width: 80vw;

    font-family: 'DM Sans', sans-serif;
    font-size: 1.8rem;
`;

const HeaderContainer = styled.div`
    display: flex;
    margin: 0 0 20px 0;
`

const TotalPrice = styled(Text)`
`

const MembershipPrice = styled(Text)`
`

const HelperText = styled(Text)`
    font-size: 0.8rem;
    margin: 0 0 0 10px;
    color: #6b6b6b;
`

const HelperLink = styled.a`
    color: #23c0b9;
    text-decoration: underline;
`

const FieldHeader = styled(Text)`
    font-size: 1.0rem;
    font-weight: 600;
`

const Required = styled.span`
    color: red;
    content: "*";
    
`

var au_discounts : ICoupon[] = [];
var uk_discounts : ICoupon[] = [];

var lawyers : any[] = [];

const useStyles = createStyles((theme) => ({
    root: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
      boxShadow: theme.shadows.md,
      border: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[1]
      }`,
      width: "200px",
      float: "right",
    },
  
    active: {
      backgroundImage: theme.fn.gradient({ from: 'green', to: 'blue' }),
    },
  
    control: {
      border: '0 !important',
    },
  
    labelActive: {
      color: `${theme.white} !important`,
    },
  }));

type ProposalParams = {
    id: string;
}

const INTRO_DEFAULT_TEXT : string = `
    <p> We're looking forward to working together! Please see our proposal for your legal project below. </p>
`

export default function ProposalCreator() {
    const { classes } = useStyles();
    const { id } = useParams<ProposalParams>();
    
    const { user } = useAuth();

    const [products, setProducts] = useState<Array<any>>([]);
    const [govFees, setGovFees] = useState<Array<any>>([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [searching, setSearching] = useState(false);
    const [searchResults, setSearchResults] = useState<Array<any>>([]);
    const [creating, setCreating] = useState(false);

    const [created, setCreated] = useState(false);
    const [toolTip, setToolTip] = useState(false);

    const [express, setExpress] = useState(false);

    let exp = new Date();
    exp.setDate(exp.getDate() + 60);

	const textColor = useColorModeValue('secondaryGray.900', 'white');
    const [values, setValues] = useState<CreateSLProposalsInput>({
        id: '',
        deal_id: id,
        client_name: '',
        client_number: '',
        company_name: '',
        company_number: '',
        proposal_brief: INTRO_DEFAULT_TEXT,
        delivery_date: 5,
        expiryDate: exp.toISOString().substring(0, 16),
        additional_notes: '',
        membership_price: 99,
        membership_discount_percentage: 10,
        show_member_upsell: true,
        add_membership: true,
        membership_discount: "",
        already_member: false,
        country: 'AU',
        preselected_packages: true,
        conflict_search: false,
        express_delivery_date: 0,
        email: '',
        phone: '',
        lawyer_notes: '',
        industry: '',
        manual_allocation: false,
        specific_lawyer: '',
        lpm_notes: '',
        formatted_address: '',
        project_name: '',
    });

    let should_member_discount : number = (values.add_membership || values.already_member || values.member_upsell) ? values.membership_discount_percentage : 0;
    let currency = values.country == 'AU' ? '$' : '£';
    const [errors, setErrors] = useState({
        button: false,
        multiple_govs: false,
        deal: false,
        client_name: false,
        delivery_date: false,
        expiryDate: false,
        member_discount: false,
        packages: false,
        company_name: false,
        proposal_brief: false,
        linked_gov: false,
        conflict_search: false,
        express_delivery_date: false,
        industry: false,
        project_name: false,
        lawyer_notes: false,
    });

    /*
    useEffect(() => {
        if( values.deal_id !== undefined && loading && id){
            lookupDeal(values.deal_id)
        }
    }, [values.deal_id, loading]);
    */

    useEffect(() => {

        console.log(user.attributes)
        async function fetch(){
            setLoading(true);
            uk_discounts = [];
            au_discounts = [];
            
            //console.log(query.get("deal"))
            let c = await getCoupons(); 
            for(let i = 0; i < c.Items.length; i++){
                let item = c.Items[i];
                switch(item.country){
                    case 'AU': 
                        au_discounts.push({api: item.coupon_id, name: item.coupon_name, type: item.coupon_type, price: item.coupon_value, country: item.country});  
                        break;
                    case 'UK':
                        uk_discounts.push({api: item.coupon_id, name: item.coupon_name, type: item.coupon_type, price: item.coupon_value, country: item.country}); 
                        break;
                }

            }

            let l = await getLawyers();
            for(let i = 0; i < l.length; i++){
                let item = l[i];

                lawyers.push({
                    value: item['Staff Name'],
                    label: item['Staff Name'],
                });

            }
            console.log(lawyers)
            setLoading(false)



        }

        fetch();


        
        
      }, []);

    const handleChange = (prop : string) => (event : React.ChangeEvent<HTMLInputElement>) => {
        //console.log(event.target.value)
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleProductChange = (prop : string, index : number, value : any) => {
        let temp = [...products];
        temp[index][prop] = value;

        //console.log(prop, index, value, temp)
        setProducts(temp);
    };

    const handleGovFeeChange = (prop : string, index : number, value : any) => {
        let temp = [...govFees];
        temp[index][prop] = value;
        setGovFees(temp);
    };

    async function addProductsFromPipedrive(ids: string[]){
        let temp: any[] = [] 
        await setProducts([]);
        await setGovFees([]);

        await Promise.all(ids.map(async (id) => {
            let item = await axios.get(`https://api.pipedrive.com/v1/products/${id}?api_token=be6a00210d66db26c38a49ee86774bf098c9c87e`)

                const package_res = await (API.graphql({
                    query: queries.getSLProducts, 
                    variables : { SKU : item.data.data.code }
                }) as Promise<ProductGetResult>)

                console.log(package_res)
                let package_data = package_res.data.getSLProducts;

                if(package_data === null){
                    return;
                }

                if(package_data.SKU === "Z0003" || package_data.SKU === "EWZ0003"){
                    setExpress(true)
                }

                temp.push({
                    SKU: package_data.SKU,
                    package_name: package_data.package_name,
                    price: package_data.price,
                    scope: `
                    ${package_data.summary !== null && package_data.summary !== undefined ? "<b>WHAT'S IT FOR?</b><br/>" : ""}${package_data.summary !== null && package_data.summary !== undefined ? package_data.summary+"<br/><br/>": ""}
                    <b>WHAT'S INCLUDED?</b>
                    ${package_data.scope}
                    `,
                    gov_fees: package_data.gov_fees,
                    pipedrive: package_data.pipedrive
                })
        }))
        addProducts(temp);
    }

    async function lookupDeal(deal : string) {
        console.log(deal, "LOOKUP")
        try {
            if(deal !== ""){


                
                setLoading(true)
                let deal_res = await axios.get(`https://api.pipedrive.com/v1/deals/${deal}?api_token=be6a00210d66db26c38a49ee86774bf098c9c87e`)
                console.log(deal_res.data.data)
                let country = "AU";
                switch(deal_res.data.data.pipeline_id){
                    case 1:
                    case 26:
                    case 4:
                        country = "AU";
                        //console.log("SWITCHING TO AU")
                        break;
                    case 29:
                    case 28:
                        country = "UK";
                        //console.log("SWITCHING TO UK")
                        break;
                }
                console.log(deal_res.data.data.pipeline_id, country)
                let org_res = await axios.get(`https://api.pipedrive.com/v1/organizations/${deal_res.data.data.org_id.value}?api_token=be6a00210d66db26c38a49ee86774bf098c9c87e`)
                console.log(org_res.data.data)
                let company_number = org_res.data.data['50d8d7b109cc3cb5d741b6aeeaf55364f539fb0a'] ? org_res.data.data['50d8d7b109cc3cb5d741b6aeeaf55364f539fb0a'] : org_res.data.data['7ae53708b7500dc8c7f67c18567f94a97740930d']
                let already_member = org_res.data.data['091be3473ea570d4a7b0ab9fc943591f43f4041f'] === "237" ? true : false;
                let conflict = deal_res.data.data['91bf24f2d005893911977f1f7d84676269f8adc8'] !== "" && deal_res.data.data['91bf24f2d005893911977f1f7d84676269f8adc8'] !== null ? true : false;
                let email =  deal_res.data.data.person_id.email[0].value ? deal_res.data.data.person_id.email[0].value : "";
                let phone =  deal_res.data.data.person_id.phone[0].value ? deal_res.data.data.person_id.phone[0].value : "";

                let address = org_res.data.data.address;

                let products_res = await axios.get(`https://api.pipedrive.com/v1/deals/${deal}/products?api_token=be6a00210d66db26c38a49ee86774bf098c9c87e`);

                if(products_res.data.data !== null){
                                        
                    let temp = [];
                    for(let i = 0; i < products_res.data.data.length; i++){
                        temp.push(products_res.data.data[i].product_id)
                    }
                    addProductsFromPipedrive(temp)
                }

                let tempValues = {...values}

                tempValues.client_number = org_res.data.data["675f0b6b171bfcfa9f171a0b4990b531b69c3cc3"];
                tempValues.company_number = company_number ? company_number.replace(/\s+/g, '') : "";
                tempValues.company_name = deal_res.data.data.org_name;
                tempValues.client_name = deal_res.data.data.person_name;
                tempValues.already_member = already_member;
                tempValues.show_member_upsell = !already_member;
                tempValues.add_membership = !already_member;
                tempValues.conflict_search = conflict;
                tempValues.country = country;
                tempValues.email = email;
                tempValues.phone = phone;

                if(address !== null && address !== undefined){
                    tempValues.formatted_address = address;
                }

                await setValues(tempValues);

                console.log(values)
                setLoading(false)

              
    
            } else {
                setErrors({ ...errors, deal: true });
    
            }
        }
        catch(e){
            console.log(e)
        }
        
    }


    function setPackageDiscount(value : string, index: number){
        let temp = [...products];
        if(value !== ""){
            if(parseFloat(value) < temp[index].base_price){
                temp[index].discount = value;
                temp[index].price = temp[index].base_price - parseFloat(temp[index].discount);
                setProducts(temp);
            } else {
                temp[index].discount = temp[index].base_price;
                temp[index].price = temp[index].base_price - temp[index].base_price;
                setProducts(temp);
            }

        } else {
            temp[index].discount = 0;
            temp[index].price = temp[index].base_price;
            setProducts(temp);
        }
    }
    
    function setPackageBasePrice(value : string, index: number){
        let temp = [...products];
        if(value !== ""){
            if(parseFloat(value) > 0){
                temp[index].base_price = value;
                temp[index].price = parseFloat(value) - parseFloat(temp[index].discount);
                setProducts(temp);
            } else {
                temp[index].base_price = 0;
                temp[index].price = 0;
                setProducts(temp);
            }

        } else {
            temp[index].base_price = 0;
            temp[index].price = 0;
            setProducts(temp);
        }
    }


    async function searchPackages(value : string) {



            setSearching(true)
            setSearchResults([]);


            if(value !== ""){
                let filter = {
                    and: [{
                        searchquery : { contains: value.toLowerCase() }
                    }, {
                        country: { contains: values.country }
                    }]
                }

                let res : any[] = [];

                const search = await (API.graphql({
                    query: queries.listSLProducts, 
                    variables : { filter: filter , limit: 1000}
                }) as Promise<ProductListResult>);
                
                //console.log(search)
                let items = search.data.listSLProducts.items;

                res = items.map((item) => ({...item, value: item.package_name}));
                //console.log(res)
                setSearchResults(res);

                

            }

            setSearching(false);


    }

    const debouncedSearch = debounce(searchPackages, 500);

    async function getGovermentPackage(id : string, link_package: string){
        const package_res = await (API.graphql({
            query: queries.getSLProducts, 
            variables : { SKU : id }
        }) as Promise<ProductGetResult>);

        let item = package_res.data.getSLProducts;

        return {
            sku: item.SKU,
            name: item.package_name,
            price: parseFloat(item.price),
            scope: `
            ${item.summary !== null && item.summary !== undefined ? "<b>WHAT'S IT FOR?</b><br/>" : ""}${item.summary !== null && item.summary !== undefined ? item.summary+"<br/><br/>": ""}
            <b>WHAT'S INCLUDED?</b>
            ${item.scope}
            `,
            link: link_package,
            gst_free: true,
            pipedrive: item.pipedrive,
        }


    }

    const handleDiscount = (event : React.ChangeEvent<HTMLSelectElement>) => {

        let discounts = values.country === "AU" ? au_discounts : uk_discounts;
        let discount = discounts.find((item) => item.api === event.target.value);
        //console.log(discount, event.target.value)
        setValues({ ...values, membership_discount: event.target.value, membership_price: parseFloat(discount.price) });
    };
    

    async function addProducts(items: any[]){
        let temp = [...products];
        let temp_gov = [...govFees];

        await Promise.all(items.map(async (item) => {
            if(item.SKU.includes("G")){
                temp_gov.push({
                    sku: item.SKU,
                    name: item.package_name,
                    price: parseFloat(item.price),
                    scope: `
                    ${item.summary !== null && item.summary !== undefined ? "<b>WHAT'S IT FOR?</b><br/>" : ""}${item.summary !== null && item.summary !== undefined ? item.summary+"<br/><br/>": ""}
                    <b>WHAT'S INCLUDED?</b>
                    ${item.scope}
                    `,
                    link: "",
                    gst_free: true,
                    pipedrive: item.pipedrive,
                });
            } else {

                if(item.SKU === "Z0003" || item.SKU === "EWZ0003"){
                    setExpress(true)
                }
                
                //console.log(item.summary)
                temp.push({
                    sku: item.SKU,
                    name: item.package_name,
                    price: parseFloat(item.price.replace(/,/g, '')),
                    scope: `
                    ${item.summary !== null && item.summary !== undefined ? "<b>WHAT'S IT FOR?</b><br/>" : ""}${item.summary !== null && item.summary !== undefined ? item.summary+"<br/><br/>": ""}
                        <b>WHAT'S INCLUDED?</b>
                        ${item.scope}
                    `,
                    discount: 0,
                    base_price: parseFloat(item.price.replace(/,/g, '')),
                    optional: false,
                    gst_free: false,
                    pipedrive: item.pipedrive,
                })
                console.log(temp)

                
                if(item.gov_fees !== null){
                    let fees = item.gov_fees.split(",");

                    await Promise.all(fees.map(async (fee : string) => {
                        console.log(fee)
                        let fee_res = await getGovermentPackage(fee.trim(), item.package_name);
                        temp_gov.push(fee_res);
                    }))

                    if(fees.length > 1){
                        setErrors({ ...errors, multiple_govs: true });
                    } 
          
                    
                }
    
            }
        }))


        setProducts(temp);
        setGovFees(temp_gov);
    }

    async function onPackageSelect(item: AutocompleteItem) {
        setSearch("");
        addProducts([item]);
        console.log(item)
    }

    function removePackage(index : number) {

        let product = products.find((item, i) => i === index);
        console.log(product)
        if(product.sku === "Z0003" || product.sku === "EWZ0003"){
            setExpress(false)
        }

        setProducts(prevProducts => prevProducts.filter((item, i) => i !== index));
    }

    function removeGovFees(index : number) {


        setGovFees(prevFees => prevFees.filter((item, i) => i !== index));
    }

    const member_discount_value : number = useMemo(() => {
        let total = 0;

        if(values.already_member || values.add_membership || values.member_upsell){
            let mem_disc = values.membership_discount_percentage / 100;

            products.forEach((item) => {

                total += (parseFloat(item.base_price)) * mem_disc;
            })
    
        }

        return total; 
    }, [products, govFees, values]);

    const project_title : string = useMemo(() => {
        let title = "";

        if(products.length > 0) {
            if(products.length === 1){
                title = products[0].name;
            }
            else if(products.length === 2){
                title = products[0].name + " & " + products[1].name;
            }
            else {
                title = products[0].name + ", " + products[1].name + " & more";
            }
        }


        return title; 
    }, [products, govFees, values]);


    const member_discount_value_not_optional : number = useMemo(() => {
        let total = 0;

        if(values.already_member || values.add_membership || values.member_upsell){
            let mem_disc = values.membership_discount_percentage / 100;

            products.forEach((item) => {

                if(!item.optional) total += (parseFloat(item.base_price)) * mem_disc;

            })
    
        }

        return total; 
    }, [products, govFees, values]);

    const value_tax_free : number = useMemo(() => {
        let total = 0;

        products.forEach((item) => {

                total += parseFloat(item.base_price);
            
        })


        govFees.forEach((item) => {
            total += parseFloat(item.price);
        })

        return total; 
    }, [products, govFees, values]);


    function getPrice(item : any) : number{
        let price : number = parseFloat(item.base_price) - parseFloat(item.discount);

        if(values.already_member || values.add_membership || values.member_upsell){
            let mem_disc = values.membership_discount_percentage / 100;

            price -= price * mem_disc;
        }

        return price;
    }


    const tax : number = useMemo(() => {
        let total = 0;
        let tax_val : number = values.country === "AU" ? 0.1 : 0.2;

        products.forEach((item) => {
            if(!item.gst_free && !item.government_fee){

                    total += getPrice(item);


            }
        })

        if(values.add_membership){
            total += values.membership_price;
        }

        return total * tax_val;

    }, [products, govFees, values]);  
    

    const discount : number = useMemo(() => {
        let total = 0;


        let mem_disc = values.membership_discount_percentage / 100;

        products.forEach((item) => {

                if(values.already_member || values.add_membership){
                    total += (parseFloat(item.base_price) * mem_disc) + parseFloat(item.discount);
                }
                else {
                    total += parseFloat(item.discount);
                }

            
        })

        return total; 
    }, [products, govFees, values]);  

    const value : number = useMemo(() => {
        let total = value_tax_free;

        if( values.add_membership){
            total += values.membership_price;
        }

        total -= discount;
        
        total += tax;

        return total; 
    }, [products, govFees, values]);

    const ReturnBiz : boolean = useMemo(() => {
        let temp = true;

        if(values.client_number === null || values.client_number === "") return false;

       return temp; 

    }, [values])

    function formatProducts() : ProposalProduct[] {
        let temp : ProposalProduct[] = [];

        for(let i = 0; i < products.length; i++){
            temp.push({
                name: products[i].name,
                price: products[i].base_price,
                optional: products[i].optional,
                description: products[i].scope,
                discount: products[i].discount,
                SKU: products[i].sku,
                pipedrive: products[i].pipedrive,
                gst_free: products[i].gst_free,
                government_fee: false,
            })
        }

        if(govFees.length > 0){
            for(let i = 0; i < govFees.length; i++){
                temp.push({
                    name: govFees[i].name,
                    price: govFees[i].price,
                    optional: govFees[i].optional,
                    description: govFees[i].scope,
                    government_fee: true,
                    SKU: govFees[i].sku,
                    link: govFees[i].link,
                    pipedrive: govFees[i].pipedrive,
                    gst_free: govFees[i].gst_free,
                })
            }
        }


        return temp;
    }

    async function validate() : Promise<boolean> {
        let valid = true;

        await setErrors({
            button: false,
            client_name: false,
            delivery_date: false,
            expiryDate: false,
            member_discount: false,
            deal: false,
            multiple_govs: false,
            packages: false,
            company_name: false,
            proposal_brief: false,
            linked_gov: false,
            conflict_search: false,
            express_delivery_date: false,
            industry: false,
            lawyer_notes: false,
            project_name: false
        })

        let temp = {...errors};

        console.log(temp)

        if(values.delivery_date === 0 || values.delivery_date === null){
            temp.delivery_date = true;
            valid = false;
        }

        if(values.expiryDate === "" || values.expiryDate === null){
            temp.expiryDate = true;
            valid = false;
        }

        if((values.add_membership === true || values.show_member_upsell) && values.membership_discount === ""){
            if(!values.already_member){
                temp.member_discount = true;
                valid = false;
            }
        }

        if(products.length === 0){
            temp.packages = true;
            valid = false;
        }

        if(values.deal_id === "" || values.deal_id === undefined){
            temp.deal = true;
            valid = false;
        }

        if(values.client_name === "" || values.client_name === null){
            temp.client_name = true;
            valid = false;
        }

        if(ReturnBiz && (values.company_name === "" || values.company_name === null)){
            temp.company_name = true;
            valid = false;
        }

        if(values.proposal_brief === "" || values.proposal_brief === null){
            temp.proposal_brief = true;
            valid = false;
        }


        if(values.express_delivery_date === 0 && express){
            temp.express_delivery_date = true;
            valid = false;
        }

        if(values.conflict_search === false && values.country === "AU"){
            temp.conflict_search = true;
            valid = false;
        }

        if(govFees.length >= 1){
            var govFeeArr = govFees.map(function(item){ return item.link });
            var isDuplicate = govFeeArr.some(function(item, idx){ 
                return govFeeArr.indexOf(item) != idx 
            });

            if(isDuplicate){
                temp.linked_gov = true;
                valid = false;
            }
        }

        if(values.lawyer_notes === "" || values.lawyer_notes === null || values.lawyer_notes === "<p><br></p>"){
            temp.lawyer_notes = true;
            valid = false;
        }

        if(values.project_name === "" || values.project_name === null){
            temp.project_name = true;
            valid = false;
        }

        if(values.industry === "" || values.industry === null){
            if(!ReturnBiz){
                temp.industry = true;
                valid = false;
            } 
        }


        if(!valid) temp.button = true;

        await setErrors(temp);

        setTimeout(function(){
            return setErrors({
                ...temp, 
                button: false,
            });
    
        }, 5000);

        return valid;
    }

    
    function formatPipedriveProducts(){
        let temp = [];

        for(let i = 0; i < products.length; i++){
            temp.push({
                product_id: parseInt(products[i].pipedrive),
                item_price: products[i].price,
                quantity: 1,
            })
        }

        if(govFees.length > 0){
            for(let i = 0; i < govFees.length; i++){
                temp.push({
                    product_id: parseInt(govFees[i].pipedrive),
                    item_price: govFees[i].price,
                    quantity: 1,
                })
            }
        }

        return temp;
    }

    async function deleteProductsPipedrive(proposal : CreateSLProposalsInput){
        try {
            //GET PRODUCTS FROM PIPEDRIVE DEAL
            let deal_products : DealProductListResult = await axios.get(`https://api.pipedrive.com/v1/deals/${proposal.deal_id}/products?api_token=be6a00210d66db26c38a49ee86774bf098c9c87e`)
            console.log(deal_products)

            if(deal_products.data.data !== null){
                //DELETE ALL PRODUCTS FROM PIPEDRIVE DEAL
                for(let i = 0; i < deal_products.data.data.length; i++){
                    await axios.delete(`https://api.pipedrive.com/v1/deals/${proposal.deal_id}/products/${ deal_products.data.data[i].id}?api_token=be6a00210d66db26c38a49ee86774bf098c9c87e`)
                }
            }

        } catch (e) {
            console.error(e)
        }
    }

    async function updatePipedrive(proposal : CreateSLProposalsInput){

        try {

            let pipedrive_products = await formatPipedriveProducts();
            console.log(pipedrive_products)
            //ADD ALL PRODUCTS TO PIPEDRIVE DEAL
            for(let i = 0; i < pipedrive_products.length; i++){
                await axios.post(`https://api.pipedrive.com/v1/deals/${proposal.deal_id}/products?api_token=be6a00210d66db26c38a49ee86774bf098c9c87e`, pipedrive_products[i])
            }

            //VIEW 39921c23d751f6ca20f5f04f43275315041d7c0c
            //EDIT 9febaf4fe9c856bf360a3f5ba96f28ddd814fba0
            
            //UPDATE VIEW EDIT FIELDS
            await axios.put(`https://api.pipedrive.com/v1/deals/${proposal.deal_id}?api_token=be6a00210d66db26c38a49ee86774bf098c9c87e`, {
                "39921c23d751f6ca20f5f04f43275315041d7c0c" : "https://proposal.sprintlaw.co/" + proposal.id,
                "9febaf4fe9c856bf360a3f5ba96f28ddd814fba0" : "https://admin.sprintlaw.co/#/admin/proposals/edit/" + proposal.id,
            })
            

        } catch (e) {
            console.error(e)
        }

    }
    

    async function createProposal(){
        setCreating(true);

        if(await validate()){
            let current_date = new Date();
            let id = nanoid();
            
            let search = 
            (values.client_name ? values.client_name.toLowerCase() : "") + " " + 
            (values.client_number ? values.client_number.toLowerCase() : "" ) + " " + 
            (values.company_name ? values.company_name.toLowerCase() : "") + " " +
            (values.deal_id ? values.deal_id.toLowerCase() : "") + " " +
            user.attributes.name + " " +
            (id.toLowerCase());

            let proposal : CreateSLProposalsInput = {
                id: id,
                client_name: values.client_name,
                client_number: values.client_number,
                company_name: values.company_name,
                company_number: values.company_number,
                additional_notes: values.additional_notes,
                country: values.country,
                createdAt: current_date.toISOString().substring(0, 16),
                expiryDate: values.expiryDate,
                deal_id: values.deal_id,
                delivery_date: values.delivery_date,
                proposal_brief: values.proposal_brief,
                status: "ACTIVE",
                products: formatProducts(),
                membership_discount: values.membership_discount,
                membership_price: values.membership_discount !== "" ? values.membership_price : 0,
                membership_discount_percentage: values.membership_discount_percentage,
                show_member_upsell: values.show_member_upsell,
                add_membership: values.add_membership,
                already_member: values.already_member,
                value: parseFloat(value.toFixed(2)),
                preselected_packages: values.preselected_packages,
                conflict_search: values.conflict_search,
                express_delivery_date: values.express_delivery_date,
                creator: user.attributes.name,
                searchquery: search,
                phone: values.phone,
                email: values.email,
                specific_lawyer: values.specific_lawyer,
                manual_allocation: values.manual_allocation,
                lawyer_notes: values.lawyer_notes,
                lpm_notes: values.lpm_notes,
                project_name: values.project_name,
                industry: values.industry,
                formatted_address: values.formatted_address,
            }
    

            console.log(proposal)
            //let new_proposal = await createProposalAPI(proposal);
            let new_proposal = await API.graphql({ query: mutations.createSLProposals, variables: {input: proposal}});
            setValues({ ...values, id: id });
            await deleteProductsPipedrive(proposal);
            await updatePipedrive(proposal);
            setCreating(false)
            setCreated(true);
            
            console.log(new_proposal)
        } else {
            setCreating(false);
        }


    }

    interface ItemProps extends SelectItemProps {
        SKU: string;
        price: string,
        country: string
    }

      
    const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(

        ({ SKU, price, value, country, ...others }: ItemProps, ref) => 

          <div ref={ref} {...others}>
            <div>
                <Text>{SKU}</Text>
                <Text size="xs" color="dimmed">
                    {value} 
                </Text>
                <Text size="xs" color="dimmed">
                    {currency}{price} 
                </Text>
            </div>
         </div>
    );



    // Chakra Color Mode
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <SimpleGrid
                mb='20px'
                columns={{ sm: 1, md: 1 }}
                spacing={{ base: "20px", xl: "20px" }}>

                        <FormControl>
                            <Stack spacing={7}>
                                {!created && <>
                                <Section shadow="sm" p="xl" radius="md" withBorder>



                                    <Stack spacing={3}>
                                        <HeaderContainer>
                                            <SectionHeader order={1}>Settings</SectionHeader> 
                                            <SegmentedControl
                                                    radius="xl"
                                                    size="md"
                                                    data={['AU', 'UK']}
                                                    classNames={classes}
                                                    onChange={(e) => setValues({ ...values, country: e, delivery_date: e === "AU" ? 5 : 7 })}
                                                    value={values.country}
                                            />
                                        </HeaderContainer>


                                        <FieldHeader>Deal ID <Required>*</Required></FieldHeader>
                                        <Stack direction="row">
                                            <Input
                                                placeholder="Deal ID" 
                                                name="deal_id" 
                                                onChange={handleChange("deal_id")} 
                                                onKeyDown={(e) => {
                                                    if(e.key === "Enter") lookupDeal(values.deal_id)
                                                }}
                                                value={values.deal_id}
                                                size='md'
                                                variant='outline'
                                                isInvalid={errors.deal}
                                                errorBorderColor='red.300'
                                            />
                                            <Button onClick={() => lookupDeal(values.deal_id)} isLoading={loading}> LOOKUP </Button>
                                        </Stack>

                                        <FieldHeader>Client Name<Required>*</Required></FieldHeader>
                                        <Input
                                            placeholder="Client Name" 
                                            name="client_name" 
                                            onChange={handleChange("client_name")} 
                                            value={values.client_name}
                                            size='md'
                                            variant='outline'
                                            isInvalid={errors.client_name}
                                            errorBorderColor='red.300'
                                        />
                                        {ReturnBiz && <HelperText>Since this is a returning client please submit a contact update request form to update their details.</HelperText>}
                                        {!ReturnBiz && <HelperText>If you are unsure please leave this field blank for the client to complete.</HelperText>}

                                        <FieldHeader>Email</FieldHeader>
                                        <Input
                                            placeholder="Email" 
                                            name="email" 
                                            onChange={handleChange("email")} 
                                            value={values.email}
                                            size='md'
                                            variant='outline'
                                            errorBorderColor='red.300'
                                        />
                                        {ReturnBiz && <HelperText>Since this is a returning client please submit a contact update request form to update their details.</HelperText>}
                                        {!ReturnBiz && <HelperText>If you are unsure please leave this field blank for the client to complete.</HelperText>}

                                        <FieldHeader>Phone</FieldHeader>
                                        <Input
                                            placeholder="Phone" 
                                            name="phone" 
                                            onChange={handleChange("phone")} 
                                            value={values.phone}
                                            size='md'
                                            variant='outline'
                                            errorBorderColor='red.300'
                                        />
                                        {ReturnBiz && <HelperText>Since this is a returning client please submit a contact update request form to update their details.</HelperText>}
                                        {!ReturnBiz && <HelperText>If you are unsure please leave this field blank for the client to complete.</HelperText>}

                                        <FieldHeader>Company Name {ReturnBiz && <Required>*</Required>}</FieldHeader>
                                        <Input
                                            placeholder="Company Name" 
                                            name="company_name" 
                                            onChange={handleChange("company_name")} 
                                            value={values.company_name}
                                            size='md'
                                            variant='outline'
                                            errorBorderColor='red.300'
                                            disabled={ReturnBiz}
                                        />
                                        {ReturnBiz && <HelperText>Since this is a returning client please submit a contact update request form to update their details.</HelperText>}
                                        {!ReturnBiz && <HelperText>If you are unsure please leave this field blank for the client to complete.</HelperText>}

                                        <FieldHeader>{values.country === "AU" ? "ABN / ACN" : "Company Number"}</FieldHeader>
                                        <Input
                                            placeholder={values.country === "AU" ? "ABN / ACN" : "Company Number"}
                                            name="company_number" 
                                            onChange={handleChange("company_number")} 
                                            value={values.company_number}
                                            size='md'
                                            variant='outline'
                                            errorBorderColor='red.300'
                                            disabled={ReturnBiz}
                                        />
                                        {ReturnBiz && <HelperText>Since this is a returning client please submit a contact update request form to update their details.</HelperText>}
                                        {!ReturnBiz && <HelperText>If you are unsure please leave this field blank for the client to complete.</HelperText>}

                                        <FieldHeader>Client Number</FieldHeader>
                                        <Input
                                            placeholder="Client Number" 
                                            name="client_number" 
                                            onChange={handleChange("client_number")} 
                                            value={values.client_number}
                                            size='md'
                                            variant='outline'
                                            disabled
                                        />

                                        <FieldHeader>Proposal Intro <Required>*</Required></FieldHeader>
                                        <RTE value={values.proposal_brief} onChange={(value : string) =>setValues({ ...values, proposal_brief: value})} id="brief"/>
                                        <HelperText>This message will appear at the top of the Proposal. You can use this sample text or customise it.</HelperText>
                                        {errors.proposal_brief && <Text color='red.500' fontSize='sm'>Please fill out the proposal breif.</Text>}

                                        <FieldHeader>Estimated Delivery Date <Required>*</Required></FieldHeader>
                                        <NumberInput 
                                            defaultValue={5} 
                                            min={1} 
                                            max={100} 
                                            onChange={(value) => setValues({ ...values, delivery_date: parseInt(value)})} 
                                            value={values.delivery_date}
                                            isInvalid={errors.delivery_date}
                                            errorBorderColor='red.300'
                                        >
                                            <NumberInputField />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>


                                        {express && <>  
                                        <FieldHeader>Express Delivery Date <Required>*</Required></FieldHeader>
                                        <NumberInput 
                                            defaultValue={3} 
                                            min={1} 
                                            max={100} 
                                            onChange={(value) => setValues({ ...values, express_delivery_date: parseInt(value)})} 
                                            value={values.express_delivery_date}
                                            isInvalid={errors.express_delivery_date}
                                            errorBorderColor='red.300'
                                        >
                                            <NumberInputField />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>
                                        </>}

                                        <FieldHeader>Expiration Date <Required>*</Required></FieldHeader>
                                        <Input
                                            placeholder="Expiration Date"
                                            size="md"
                                            type="datetime-local"
                                            value={values.expiryDate}
                                            onChange={handleChange("expiryDate")} 
                                        />
                                        <HelperText>This proposal will expire in 30 days by default you can customise this by using this field. Once the document expires the client will no longer be able to pay for the quote.</HelperText>

                                        <FieldHeader>Additional Notes</FieldHeader>
                                        <RTE value={values.additional_notes} onChange={(value : string) =>setValues({ ...values, additional_notes: value})} id="notes"/>
                                        <HelperText>The content of this field will appear at the bottom of the Proposal. Use it to provide any additional information not contained in the above Proposal such as disclaimers or other limitations to scope.</HelperText>

                                        {values.country === "AU" && 
                                        <>
                                            <FieldHeader>Conflict Search <Required>*</Required></FieldHeader>
                                            {!values.conflict_search && <HelperText>Please <HelperLink href={"https://sprintlaw.formstack.com/forms/conflicts?deal_id="+values.deal_id} target="_blank">complete the search</HelperLink>.</HelperText>}
                                            <Checkbox 
                                                isChecked={values.conflict_search} 
                                                onChange={(e) => setValues({...values, conflict_search: e.target.checked})} 
                                                isInvalid={errors.conflict_search}
                                            >
                                                Completed
                                            </Checkbox>
                                        </>
                                        }

                                        
                                    </Stack>
                                </Section>

                                

                               
                                        
                                <Section shadow="sm" p="lg" radius="md" withBorder>
                                    <Stack spacing={3}>
                                        <HeaderContainer>                                        
                                            <SectionHeader order={1}>Fixed Fee Packages</SectionHeader>
                                        </HeaderContainer>
                                        {errors.packages && <Text color='red.500' fontSize='sm'>Please add at least one package to this proposal.</Text>}

    


                                        <Text color={textColor} fontSize='19px' fontWeight='700' lineHeight='100%'>
                                            Select Packages
                                        </Text>
                                        
                                        
                                        <Autocomplete
                                            onChange={debouncedSearch}
                                            placeholder="Start typing to see packages"
                                            itemComponent={AutoCompleteItem} 
                                            rightSection={searching ? <Loader size={16} /> : null}
                                            data={searchResults}
                                            limit={8}
                                            onItemSubmit={onPackageSelect}
                                            dropdownPosition="bottom"
                                            style={{
                                                marginBottom: '0px'
                                            }}
                                        />
                                        <HelperText>
                                            You can search for fixed fee packages or government fees using the field above.
                                        </HelperText>



                                        <PackageTable 
                                            tableData={products}
                                            columnsData={packageColumns}
                                            removePackage={removePackage}
                                            setPackageDiscount={setPackageDiscount}
                                            setPackageBasePrice={setPackageBasePrice}
                                            handleProductChange={handleProductChange}
                                            country={values.country} 
                                            member_discount={should_member_discount}
                                        />

                                        
                                        <Text color={textColor} fontSize='19px' fontWeight='700' lineHeight='100%'>
                                            Optional Packages Pre-selected?
                                        </Text>
                                        <Checkbox isChecked={values.preselected_packages} onChange={(e) => setValues({...values, preselected_packages: e.target.checked})} >Yes</Checkbox>
                                        <HelperText>
                                            By default all packages are selected on the proposal. If you would like the optional addons to be deselected untick this.
                                        </HelperText>


                                        <GovFeesTable 
                                            tableData={govFees} 
                                            columnsData={govFeeColumns} 
                                            products={products} 
                                            handleGovFeeChange={handleGovFeeChange} 
                                            removeGovFees={removeGovFees} 
                                            country={values.country}
                                            error={errors.linked_gov}
                                        />


                                    </Stack>
                                </Section>

                                <Section shadow="sm" p="lg" radius="md" withBorder>
                                    <Stack spacing={5}>
                                        <HeaderContainer>                                        
                                            <SectionHeader order={1}>Membership</SectionHeader>
                                        </HeaderContainer>

                                        {values.already_member && <>
                                            <FieldHeader>Membership</FieldHeader>
                                            <Text color='red.500' fontSize='sm'>Client is already a member.</Text>
                                            <HelperText>This client is already a member use this field to customise the discounnt applied to this proposal.</HelperText>
                                        </>}

                                        {!values.already_member && <>


                                            <Checkbox isChecked={values.show_member_upsell} onChange={(e) => setValues({...values, show_member_upsell: e.target.checked})} >Show Membership Upsell Option</Checkbox>
                                         
                                            <Checkbox isChecked={values.add_membership} onChange={(e) => setValues({...values, add_membership: e.target.checked})} >Include Membership by Default</Checkbox>
                                            <HelperText>
                                                You should generally select both options above. If you do not select the first option the client will not be presented with an upsell. If you do not select the second option a membership will not automatically be added to the proposal.
                                            </HelperText>

                                            {(values.show_member_upsell || values.add_membership) &&
                                            <>
                                                <FieldHeader >Membership Promotion <Required>*</Required></FieldHeader>
                                                <Select
                                                    value={values.membership_discount}
                                                    name="discount"
                                                    onChange={handleDiscount}
                                                    isInvalid={errors.member_discount}
                                                    errorBorderColor='red.300'
                                                    placeholder="Select a discount"
                                                >
                                                    { values.country === "AU" ? 
                                                    au_discounts.map(item => {
                                                        return item.type === "membership" || item.type === "both" ? (
                                                            <option value={item.api} key={item.api}>{item.name} - ${item.price}</option>
                                                        ) : (null)
                                                    })
                                                    :
                                                    uk_discounts.map(item => {
                                                        return item.type === "membership" || item.type === "both" ? (
                                                            <option value={item.api} key={item.api}>{item.name} - £{item.price}</option>
                                                        ) : (null)
                                                    })
                                                    }
                                                </Select>


                                                {products.length > 0 && 
                                                <HelperText>
                                                    <b style={{color: "#ED8936"}}>Suggested Promotion</b> : {member_discount_value_not_optional === 0 ? "" : "under"} {values.country === "AU" ? "$" : "£"}{member_discount_value_not_optional} ( or under {values.country === "AU" ? "$" : "£"}{member_discount_value} including optionals )
                                                </HelperText>
                                                }
                                            </>}
                                        </>}

                                        {(values.show_member_upsell || values.add_membership || values.already_member) && <>

                                        <FieldHeader >Membership Discount Amount <Required>*</Required></FieldHeader>
                                            <InputGroup>
                                                <InputLeftElement
                                                pointerEvents='none'
                                                color='gray.300'
                                                fontSize='1.2em'
                                                children='%'
                                                />
                                                <Input placeholder='Enter amount' value={values.membership_discount_percentage} onChange={handleChange("membership_discount_percentage")} />
                                            </InputGroup>
                                            <HelperText>
                                                    Customise this field to vary the membership discount on this proposal. The default is 10%.
                                            </HelperText>
                                        </>}
                                    </Stack>
                                </Section>

                                <Section shadow="sm" p="xl" radius="md" withBorder>
                                    <Stack spacing={3}>
                                        <HeaderContainer>
                                            <SectionHeader order={1}>Brief to Lawyer</SectionHeader> 
                                        </HeaderContainer>

                                        <FieldHeader>Project Name <Required>*</Required></FieldHeader>
                                        <Input
                                            placeholder="Project Name" 
                                            name="project_name" 
                                            onChange={handleChange("project_name")} 
                                            value={values.project_name}
                                            size='md'
                                            variant='outline'
                                            isInvalid={errors.project_name}
                                            errorBorderColor='red.300'
                                            maxLength={49}
                                        />
                                        
                                        {products.length > 0 && 
                                                <HelperText>
                                                    <b style={{color: "#ED8936"}}>Suggested</b> : {project_title}
                                                </HelperText>
                                        }
                                        <HelperText>Please <HelperLink href={"https://www.notion.so/sprintlaw/Project-Name-Email-Title-1455983931054e3eb9c61b393fba88b1"} target="_blank">read this notion page</HelperLink>. (Max 49 Characters)</HelperText>
                                        {!ReturnBiz &&<>
                                            <FieldHeader >Industry <Required>*</Required></FieldHeader>
                                            <MantineSelect
                                                placeholder="Select an Industry..."
                                                value={values.industry}
                                                name="industry"
                                                onChange={(e) => setValues({...values, industry: e})}
                                                defaultValue="ACTIVE"
                                                data={IndustryData}
                                                error={errors.industry}
                                                searchable
                                            />
                                            <HelperText>
                                                Please select the clients industry form the drop downs above. 
                                            </HelperText>
                                        </>}

                                        <FieldHeader>Lawyer Notes <Required>*</Required></FieldHeader>
                                        <RTE value={values.lawyer_notes} onChange={(value : string) =>setValues({ ...values, lawyer_notes: value})} id="lawyer-notes"/>
                                        <HelperText> Please brief the lawyer about your discussions with the client to date. Be as specific as possible, and make sure to structure your notes.</HelperText>
                                        {errors.lawyer_notes && <Text color='red.500' fontSize='sm'>Please fill in the lawyer notes for the lawyer or legal team assigned to this project.</Text>}

                                        <FieldHeader >Specific Lawyer Required</FieldHeader>
                                        <MantineSelect
                                            placeholder="Select a Specific Lawyer..."
                                            value={values.specific_lawyer}
                                            name="specific_lawyer"
                                            onChange={(e) => setValues({...values, specific_lawyer: e})}
                                            defaultValue="ACTIVE"
                                            data={lawyers}
                                            searchable
                                            clearable
                                        />
                                        <HelperText>
                                            Please specify whether a specific lawyer is required to take on this project
                                        </HelperText>

                                        <FieldHeader>Manual Allocation / Skip Booking</FieldHeader>
                                        <HelperText>Select this to skip the booking page after the proposal is accepted.<br /> If a specific lawyer has been selected it will automatically be allocated to them, otherwise the project will be allocated by LO.</HelperText>
                                        <Checkbox 
                                            isChecked={values.manual_allocation} 
                                            onChange={(e) => setValues({...values, manual_allocation: e.target.checked})} 
                                        >
                                            Manual Allocation / Skip Booking
                                        </Checkbox>
                                        {values.manual_allocation && 
                                        <> 
                                            <FieldHeader >LO Notes</FieldHeader>
                                            <RTE value={values.lpm_notes} onChange={(value : string) =>setValues({ ...values, lpm_notes: value})} id="lpm-notes"/>
                                            <HelperText> Please record any special notes for the Legal Operations Team or Legal Team Leaders.</HelperText>
                                        </>
                                        }
                                    </Stack>
                                </Section>

                                </>}
                                <Section shadow="sm" p="lg" radius="md" withBorder>
                                    <Stack spacing={10}>
                                        <Stack direction="row" spacing={10}>
                                            <Stack spacing={3}>

                                                <TotalPrice>Sub Total:</TotalPrice>
                                                {(values.add_membership) && <MembershipPrice>Membership Fee:</MembershipPrice> }
                                                {(values.already_member || values.add_membership) && <MembershipPrice>Member Discount:</MembershipPrice> }
                                                <TotalPrice>Discount:</TotalPrice>
                                                <TotalPrice>{values.country === "AU" ? "GST" : "VAT"}:</TotalPrice>
                                                <TotalPrice>Grand Total:</TotalPrice>
    
                                            </Stack>
                                            <Stack spacing={3}>
                                                <TotalPrice>{currency}{value_tax_free.toFixed(2)}</TotalPrice>
                                                {(values.add_membership) && <MembershipPrice>{currency}{values.membership_price.toFixed(2)}</MembershipPrice> }
                                                {(values.already_member || values.add_membership) && <MembershipPrice>-{currency}{member_discount_value.toFixed(2)}</MembershipPrice> }
                                                <TotalPrice>-{currency}{discount.toFixed(2)}</TotalPrice>
                                                <TotalPrice>{currency}{tax.toFixed(2)}</TotalPrice>
                                                <TotalPrice>{currency}{value.toFixed(2)}</TotalPrice>

                                            </Stack>

                                        </Stack>
                                        {created &&<Text color={textColor} fontSize='19px' fontWeight='700' lineHeight='100%' textAlign={"center"}>
                                                    Proposal for Deal <a href={"https://sprintlaw.pipedrive.com/deal/" + values.deal_id} target="_blank">{values.deal_id}</a> ({values.client_name}) created.
                                        </Text>}
                                        {!created ? 
                                        <Button 
                                                    onClick={createProposal} 
                                                    isLoading={creating}
                                                    colorScheme={errors.button ? "red" : "teal"}
                                                    >
                                                    {!creating ? (errors.button ? "Please Check Fields" : "Submit") : "Creating..."}
                                        </Button>
                                        :
                                        <>
                                        <Button 
                                                    onClick={() => window.open("https://proposal.sprintlaw.co/" + values.id, '_blank').focus()} 
                                                    isLoading={creating}
                                                    colorScheme={errors.button ? "red" : "teal"}
                                                    >
                                                    View Proposal
                                        </Button>

                                        <Button 
                                                    onClick={() => window.open("https://proposal.sprintlaw.co/" + values.id + "/brief", '_blank').focus()} 
                                                    isLoading={creating}
                                                    colorScheme={errors.button ? "red" : "teal"}
                                                    >
                                                    View Brief
                                        </Button>


                                        <Link to={`/admin/proposals/edit/${values.id}`} style={{width: "100%"}}>
                                            <Button 
                                                        isLoading={creating}
                                                        colorScheme={"orange"}
                                                        style={{width: "100%"}}
                                                        >

                                                            Edit

                                            </Button>
                                        </Link>
                                        </>}
                                    </Stack>
        


                                </Section>
                            </Stack>
                        </FormControl>

                        {created && 
                        <Section shadow="sm" p="lg" radius="md" withBorder>
                            <FieldHeader style={{textAlign: "center"}} >Link successfully generated (Click to add to clipboard):</FieldHeader>
                            <Tooltip
                                    label="Link added to clipboard"
                                    isOpen={toolTip}
                                >
                                <Button variant="text" onClick={()=> {
                                    navigator.clipboard.writeText("https://proposal.sprintlaw.co/" + values.id)
                                    setToolTip(true);

                                    setTimeout(() => {
                                        setToolTip(false)
                                    }, 1000);
                                }}
                                style={{
                                    margin: '5px auto',
                                    width: '100%'
                                }}>
                                    https://www.proposal.sprintlaw.co/{values.id}
                                </Button>
                            </Tooltip>
                        </Section>}

            </SimpleGrid>

            <Modal
                opened={errors.multiple_govs}
                onClose={() => setErrors({ ...errors, multiple_govs: false })}
                title="Warning!"
            >
                <Stack>
                    <Text>
                        You've picked a package with multiple linked government fees. Please remove those that do not apply so that there is only one linked government fee for this package.
                    </Text>
                    <Button onClick={() => setErrors({ ...errors, multiple_govs: false })} > OK </Button>
                </Stack>
            </Modal>
        </Box>
    );
}
