import { createContext, useContext, useMemo, useEffect } from "react";
import { useHistory  } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";

import { Auth, Hub } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const history = useHistory();

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          //console.log("sign in")
          getUser().then(userData => {
            history.push("/admin")
            setUser(userData)
          });
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
          console.error('Sign in failure', data);
          break;
      }
    });

    getUser().then(userData => {
      setUser(userData)
    });
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then(userData => userData)
      .catch((e) => console.error('Not signed in \n Error: ', e));
  }


  // call this function when you want to authenticate the user
  const login = async (data) => {

    await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })

  
  };

  // call this function to sign out logged in user
  const logout = () => {
    Auth.signOut()
    .then(() => {
      setUser(null);
      history.push("/auth");
    })
    .catch((e) => console.error("Not signed in \n" + e));

  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout
    }),
    [user]
  );
  return (
    <AuthContext.Provider value={value}>
        {children}
    </AuthContext.Provider>);
};

export const useAuth = () => {
  return useContext(AuthContext);
};