export const industry = [
    {
        label: "Agriculture, forestry and fishing",
        value: "Agriculture, forestry and fishing"
    },
    {
        label: "Arts & Entertainment",
        value: "Arts & Entertainment"
    },
    {   
        label: "Childcare",
        value: "Childcare"
    },
    {
        label: "Construction and Trades",
        value: "Construction and Trades"
    },
    {   
        label: "Education and training",
        value: "Education and training"
    },
    {
        label: "Engineering",
        value: "Engineering"
    },
    {
        label: "Events",
        value: "Events"
    },
    {
        label: "Fashion and apparel",
        value: "Fashion and apparel"
    },
    {
        label: "Financial and insurance services",
        value: "Financial and insurance services"
    },
    {
        label: "Food and beverage",
        value: "Food and beverage"
    },
    {
        label: "Furniture and homewares",
        value: "Furniture and homewares"
    },
    {
        label: "Hairdressing",
        value: "Hairdressing"
    },
    {
        label: "Health",
        value: "Health"
    },
    {
        label: "Hospitality",
        value: "Hospitality"
    },
    {
        label: "Legal services",
        value: "Legal services"
    },
    {
        label: "Logistics",
        value: "Logistics"
    },
    {
        label: "Marketing and Creative Services",
        value: "Marketing and Creative Services"
    },
    {
        label: "Other",
        value: "Other"
    },
    {
        label: "Professional Services",
        value: "Professional Services"
    },
    {
        label: "Real estate services",
        value: "Real estate services"
    },
    {
        label: "Social Assistance",
        value: "Social Assistance"
    },
    {
        label: "Sports and Recreation",
        value: "Sports and Recreation"
    },
    {
        label: "Technology",
        value: "Technology"
    },
    {
        label: "Tourism",
        value: "Tourism"
    },
    {
        label: "Transport",
        value: "Transport"
    }

]