export const packageColumns = [
    {
      Header: "SKU",
      accessor: "sku",
    },
    {
      accessor: "name",
    },
    {
      Header: "BASE",
      accessor: "base_price",
    },
    {
      Header: "MEMBER DISC",
    },
    {
      Header: "MANUAL DISC",
      accessor: "discount",
    },
    {
      Header: "NET PRICE",
      accessor: "price",
    },
    {
      Header: "OPTIONAL"
    },
    {
      Header: "TAX FREE"
    },
    {
      accessor: "scope",
      show: false
    },
  ];