export const discountColumns = [
  {
    Header: "API ID",
    accessor: "api",
  },
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "TYPE",
    accessor: "type",
  },
  {
    Header: "VALUE",
    accessor: "price",
  },
  {
    Header: "COUNTRY",
    accessor: "country",
  },
  {
    Header: "DELETE",
  },
];