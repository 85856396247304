import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAuth } from "../../hooks/AuthContext";
import { FC } from "react";


interface Props extends RouteProps{
    children?: React.ReactNode;
}

export const ProtectedRoute : FC<Props> = props => {
  const { user } = useAuth();
  
  if (!user) {
    // user is not authenticated
    const redirectComponent = () => <Redirect to="/auth" />;
    return <Route {...props} component={redirectComponent} render={undefined} />;
  }
  return <Route exact {...props} />;
};