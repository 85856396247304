/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, SimpleGrid } from "@chakra-ui/react";

import ProposalTable from "views/admin/proposal/components/ProposalTable";

import {useEffect, useState} from "react";

import { proposalColumns } from "views/admin/proposal/variables/proposalColumns";

import { API } from 'aws-amplify';

import * as queries from 'graphql/queries';
import * as customQueries from 'graphql/customQueries';
import { ProposalListResult } from "proposals";
import { debounce } from "lodash";
import { Loader } from "@mantine/core";

export interface IProposalRow {
    id : string,
    name: string,
    status: string,
    date: string,
    deal: string,
    country: string,
    value: number,
    org: string,
    client: string,
    creator: string,
}

function createRow(id: string, name : string, status : string, date : string, deal : string, country : string, value: number, org: string, client: string, creator: string) : IProposalRow {
    return {
        id,
        name,
        status,
        date,
        deal,
        country,
        value,
        org,
        client,
        creator
    };
}

export default function Proposal() {
    const [data, setData] = useState<Array<IProposalRow>>();
    const [loading, setLoading] = useState(true);

    const [searching, setSearching] = useState(false);
    const [search, setSearch] = useState("");
    const [searchResults, setSearchResults] = useState<Array<IProposalRow>>();
    
    useEffect(() => {


        async function setup()
        {
            setSearching(true)
            const allProposals = await (API.graphql({ query: queries.listSLProposals }) as Promise<ProposalListResult>);

            var rows : IProposalRow[] = [];

            var items = allProposals.data.listSLProposals.items;

            await Promise.all(items.map((item) => {
                return rows.push(createRow(item.id, item.client_name, item.status, item.createdAt, item.deal_id, item.country, item.value, item.company_name, item.client_number, item.creator));
            }));


            if(allProposals.data.listSLProposals.nextToken !== null){
                await getNextPage(allProposals.data.listSLProposals.nextToken, rows);
                console.log(rows)
                setData(rows.reverse());
            } else {
                console.log(rows)
                setData(rows.reverse());
            }
            setSearching(false)
            setLoading(false)
        }
        setup();
        
      }, []);

      async function getNextPage(nextToken : string, rows : IProposalRow[]) {
        console.log(rows)
        const allProposals = await (API.graphql({ query: customQueries.searchSlProposals, variables : { nextToken: nextToken, limit: 100000 } }) as Promise<ProposalListResult>);

        var items = allProposals.data.listSLProposals.items;

        await Promise.all(items.map((item) => {
            return rows.push(createRow(item.id, item.client_name, item.status, item.createdAt, item.deal_id, item.country, item.value, item.company_name, item.client_number, item.creator));
        }));

        if(allProposals.data.listSLProposals.nextToken !== null){
            await getNextPage(allProposals.data.listSLProposals.nextToken, rows);
        } else {
            return rows;
        }
      }

      async function getNextPageSearch(filter : any, nextToken : string, rows : IProposalRow[]) {
        console.log(rows)
        const allProposals = await (API.graphql({ query: customQueries.searchSlProposals, variables : { filter: filter, nextToken: nextToken, limit: 100000 } }) as Promise<ProposalListResult>);

        var items = allProposals.data.listSLProposals.items;

        await Promise.all(items.map((item) => {
            return rows.push(createRow(item.id, item.client_name, item.status, item.createdAt, item.deal_id, item.country, item.value, item.company_name, item.client_number, item.creator));
        }));

        if(allProposals.data.listSLProposals.nextToken !== null){
            await getNextPageSearch(filter, allProposals.data.listSLProposals.nextToken, rows);
        } else {
            return rows;
        }
      }

      async function searchPackages(value : string) {
        setSearching(true)
        setSearchResults([]);


        if(value !== ""){
            let filter = {

                searchquery : { contains: value.toLowerCase() }
                
            }

            let res : any[] = [];

            const search_res = await (API.graphql({
                query: customQueries.searchSlProposals, 
                variables : { filter: filter, limit: 10000 }
            }) as Promise<ProposalListResult>);

            let items = search_res.data.listSLProposals.items;
            console.log(search_res)

            await Promise.all(items.map((item) => {
                return res.push(createRow(item.id, item.client_name, item.status, item.createdAt, item.deal_id, item.country, item.value, item.company_name, item.client_number, item.creator));
            }));
            
            
            if(search_res.data.listSLProposals.nextToken !== null){
                await getNextPageSearch(filter, search_res.data.listSLProposals.nextToken, res);
                console.log(res)
                setData(res.reverse());
            } else {
                console.log(res)
                setData(res.reverse());
            }

            

        }

        setSearching(false);

    }
    
    const searchPackagesDebounced = debounce(searchPackages, 300);

    // Chakra Color Mode
    return (
        loading ?
        (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Loader color="cyan" size="xl" />
        </Box>
        ) :
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid
            mb='20px'
            columns={{ sm: 1, md: 1 }}
            spacing={{ base: "20px", xl: "20px" }}>
            {!loading && <ProposalTable tableData={data} columnsData={proposalColumns} searchPackages={searchPackagesDebounced} loading={searching} />}

        </SimpleGrid>
        </Box>
    );
}
