import axios from 'axios';
import { ICoupon, ICouponInfo, ICouponStripeData } from 'coupons';


export async function createMembershipLink(deal_id : string, type : string, discount : string, pipe : string, value : string, country : string, noDeal: boolean, subtitle: string){
    return await axios.post(`https://api.sprintlaw.co/signup/`, {deal_id: deal_id, type: type, discount: discount, pipeline: pipe, value: value, country: country, no_deal: noDeal, subtitle_text: subtitle}).then(res => res.data).catch(e => console.log(e))
}

export async function getCoupons(){
    return await axios.get(`https://api.sprintlaw.co/admin/coupons`).then(res => res.data).catch(e => console.log(e));
}

export async function createCoupon(data : ICouponStripeData, product : ICouponInfo){
    return await axios.post(`https://api.sprintlaw.co/admin/coupons`, {data: data, product: product}).then(res => res.data).catch(e => console.log(e));
}

export async function deleteCoupon(id : string){
    return await axios.delete(`https://api.sprintlaw.co/admin/coupons/${id}`).then(res => res.data).catch(e => console.log(e));
}

export async function getLawyers(){
    return await axios.get(`https://api.sprintlaw.co/admin/lawyers/`).then(res => res.data).catch(e => console.log(e));
}

export async function createProposal(data : any){
    return await axios.post(`https://ali2tpx9n7.execute-api.ap-southeast-2.amazonaws.com/staging/proposals`, data).then(res => res.data).catch(e => console.log(e));
}