import { Flex, Table, Progress, Icon, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, Tooltip, IconButton } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { Link, NavLink } from 'react-router-dom';

// Custom components
import Card from 'components/card/Card';
import Menu from 'components/menu/MainMenu';

// Assets
import { MdCheckCircle, MdCancel, MdOutlineError, MdNotInterested } from 'react-icons/md';
import { BiEdit } from 'react-icons/bi';
import { BsSearch } from 'react-icons/bs';
import { IProposalRow } from '..';
import { Button, Input, Loader } from '@mantine/core';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';


export default function ProposalTable(props: { 
	columnsData: any; 
	tableData: Array<IProposalRow> 
	searchPackages: Function,
	loading: boolean,
}) {
	const { columnsData, tableData, searchPackages, loading } = props;

	const columns = useMemo(() => columnsData, [ columnsData ]);
	const data = useMemo(() => tableData, [ tableData ]);

	const tableInstance = useTable(
		{
			columns,
			data,
			initialState : { 
				pageSize: 20,
			}
		},
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, initialState, canPreviousPage, canNextPage,nextPage, previousPage } = tableInstance;
	initialState.pageSize = 5;

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

	
	return (
		<Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Flex px='25px' justify='space-between' mb='20px' align='center'>
				<Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
					Proposals
				</Text>
				<Input
					placeholder={"Search by Client Name, Client Number, Deal ID or Company Name"}
					name="search" 
					size='lg'
					style={{width: '700px'}}
					onChange={(e : React.ChangeEvent<HTMLInputElement>) => searchPackages(e.target.value)}
					icon={!loading ? <BsSearch /> : <Loader size="xs" color="rgb(23, 192, 185)"/>}
				/>
			</Flex>
			<Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
				<Thead>
					{headerGroups.map((headerGroup, index) => (
						<Tr {...headerGroup.getHeaderGroupProps()} key={index}>
							{headerGroup.headers.map((column, index) => (
								<Th
									{...column.getHeaderProps(column.getSortByToggleProps())}
									pe='10px'
									key={index}
									borderColor={borderColor}>
									<Flex
										justify='space-between'
										align='center'
										fontSize={{ sm: '10px', lg: '12px' }}
										color='gray.400'>
										{column.render('Header')}
									</Flex>
								</Th>
							))}
						</Tr>
					))}
				</Thead>
				<Tbody {...getTableBodyProps()}>
					{page.map((row, index) => {
						prepareRow(row);
						return (
							<Tr {...row.getRowProps()} key={index}>
								{row.cells.map((cell, index) => {
									let data;
									if (cell.column.Header === 'NAME') {
										data = (
											<Text color={textColor} fontSize='sm' fontWeight='700'>

													{cell.value}
					
											</Text>
										);
									} else if (cell.column.Header === 'STATUS') {
										data = (
											<Flex align='center'>
												<Icon
													w='24px'
													h='24px'
													me='5px'
													color={
														cell.value === 'PAID' ? (
															'green.500'
														) : cell.value === 'ERROR' ? (
															'red.500'
														) : cell.value === 'ACTIVE' ? (
															'blue.500'	
														) : cell.value === 'INACTIVE' ? (
															'red.500'
														) : cell.value === 'EDIT' ? (
															'orange.500'
														) : cell.value === 'EXPIRED' ? (
															'red.500'
														) : null
													}
													as={
														cell.value === 'PAID' ? (
															MdCheckCircle
														) : cell.value === 'ERROR' ? (
															MdCancel
														) : cell.value === 'ACTIVE' ? (
															MdOutlineError
														) : cell.value === 'INACTIVE' ? (
															MdNotInterested
														) : cell.value === 'EDIT' ? (
															BiEdit
														) : cell.value === 'EXPIRED' ? (
															MdCancel
														) : null
													}
												/>
												<Text color={textColor} fontSize='sm' fontWeight='700'>
													{cell.value === "EDIT" ? "EDITING" : cell.value}
												</Text>
											</Flex>
										);
									} else if (cell.column.Header === 'DATE') {
										data = (
											<Text color={textColor} fontSize='sm' fontWeight='700'>
												{new Date(cell.value).toLocaleDateString()}
											</Text>
										);
									} else if (cell.column.Header === 'PROGRESS') {
										data = (
											<Flex align='center'>
												<Progress
													variant='table'
													colorScheme='brandScheme'
													h='8px'
													w='108px'
													value={cell.value}
												/>
											</Flex>
										);
									}
									else if (cell.column.Header === 'VALUE') {
										data = (
											<Text color={textColor} fontSize='sm' fontWeight='700'>
												${cell.value ? cell.value.toFixed(2) : "0.00"}
											</Text>
										);
									}
									else if (cell.column.Header === 'DEAL') {
										data = (
											<Text color={textColor} fontSize='sm' fontWeight='700'>
												{cell.value}
											</Text>
										);
									}
									else if (cell.column.Header === 'ORG') {
										data = (
											<Text color={textColor} fontSize='sm' fontWeight='700'>
												{cell.value}
											</Text>
										);
									}
									else if (cell.column.Header === 'CLIENT') {
										data = (
											<Text color={textColor} fontSize='sm' fontWeight='700'>
												{cell.value}
											</Text>
										);
									}
									else if (cell.column.Header === 'CREATED BY') {
										data = (
											<Text color={textColor} fontSize='sm' fontWeight='700'>
												{cell.value}
											</Text>
										);
									}
									else if (cell.column.Header === 'ACTIONS') {
										data = (
											<Flex align='center'>

												<a href={`https://proposal.sprintlaw.co/${row.original.id}`} target="_blank"> 
													<Button variant='subtle'>
													View
													</Button>
												</a>

												<a href={`https://proposal.sprintlaw.co/${row.original.id}/brief`} target="_blank"> 
													<Button variant='subtle'>
													Brief 
													</Button>
												</a>


												<Link to={`/admin/proposals/edit/${row.original.id}`}>
													<Button variant='subtle'>
														Edit
													</Button>
												</Link>

											</Flex>
										);
									}
									return (
										<Td
											{...cell.getCellProps()}
											key={index}
											fontSize={{ sm: '14px' }}
											minW={{ sm: '150px', md: '200px', lg: 'auto' }}
											borderColor='transparent'>
											{data}
										</Td>
									);
								})}
							</Tr>
						);
					})}
				</Tbody>
			</Table>
			<Flex justifyContent="space-between" m={4} alignItems="center">
        		<Flex>
					<Tooltip label="Previous Page">
						<IconButton
							onClick={previousPage}
							isDisabled={!canPreviousPage}
							icon={<ChevronLeftIcon h={6} w={6} />} aria-label={''}				/>
					</Tooltip>
					<Tooltip label="Next Page">
						<IconButton
							onClick={nextPage}
							isDisabled={!canNextPage}
							icon={<ChevronRightIcon h={6} w={6} />} aria-label={''}				/>
					</Tooltip>
			  	</Flex>
			</Flex>
		</Card>
	);
}
