import { useState } from "react";
import { 
    Radio, 
    RadioGroup,
    FormControl,
    Input,
    Select,
    Stack ,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    InputGroup,
    InputLeftElement,
    Text
} from '@chakra-ui/react'


interface IDiscountCreationProps {
    open: boolean
    onClose: React.MouseEventHandler<HTMLButtonElement>
    accept: Function
    title: String
    desc: String
}


export default function ConfirmDialog(props : IDiscountCreationProps) : JSX.Element{
    const { title, desc, accept, open, onClose} = props;

    const handleSubmit = async (event :  React.MouseEvent<HTMLButtonElement>) => {
        accept();
    }

    return (    
        <Modal isOpen={open} onClose={() => console.log("")}>
            <ModalOverlay />
            <ModalContent>
            <ModalHeader>{title}</ModalHeader>
            <ModalCloseButton onClick={onClose}/>
            <ModalBody>

                    <FormControl>

                            
                            <Text > {desc} </Text>

                    </FormControl>

            </ModalBody>

                <ModalFooter>
                    <Button  variant='ghost' mr={3} onClick={onClose}>
                        No
                    </Button>
                    <Button colorScheme='blue' onClick={handleSubmit}>Yes</Button>
                </ModalFooter>
            </ModalContent>
      </Modal>
    )
}