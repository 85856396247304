import { Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, Button, Tooltip, IconButton} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import ConfirmDialog from "./ConfirmDialog";

// Custom components
import Card from 'components/card/Card';
import { ICoupon } from 'coupons';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

export default function DiscountTable(props: 
	{ 
		columnsData: any
		tableData: Array<ICoupon>
		openCreator: any 
		deleteSelectedCoupon: any
	}) {
	const { columnsData, tableData, openCreator, deleteSelectedCoupon } = props;

	const [confirm, setConfirm] = React.useState(false);
	const [toDelete, setToDelete] = React.useState("");

	const columns = useMemo(() => columnsData, [ columnsData ]);
	const data = useMemo(() => tableData, [ tableData ]);

	const tableInstance = useTable(
		{
			columns,
			data,
			initialState : { 
				pageSize: 10,
			}
		},
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, initialState, canPreviousPage, canNextPage,nextPage, previousPage } = tableInstance;
	initialState.pageSize = 1000;

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
	return (
		<Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Flex px='25px' justify='space-between' mb='20px' align='center'>
				<Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
					Discounts
				</Text>
				<Button onClick={openCreator}> Add Coupon</Button>
			</Flex>
			<Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
				<Thead>
					{headerGroups.map((headerGroup, index) => (
						<Tr {...headerGroup.getHeaderGroupProps()} key={index}>
							{headerGroup.headers.map((column, index) => (
								<Th
									{...column.getHeaderProps(column.getSortByToggleProps())}
									pe='10px'
									key={index}
									borderColor={borderColor}>
									<Flex
										justify='space-between'
										align='center'
										fontSize={{ sm: '10px', lg: '12px' }}
										color='gray.400'>
										{column.render('Header')}
									</Flex>
								</Th>
							))}
						</Tr>
					))}

				</Thead>
				<Tbody {...getTableBodyProps()}>
					{page.map((row, index) => {
						prepareRow(row);
						return (
							<Tr {...row.getRowProps()} key={index}>
								{row.cells.map((cell, index) => {
									let data;
			
									if (cell.column.Header === 'NAME') {
										data = (
											<Flex align='center'>
												<Text color={textColor} fontSize='sm' fontWeight='700'>
													{cell.value}
												</Text>
											</Flex>
										);
									} else if (cell.column.Header === 'API ID') {
										data = (
											<Flex align='center'>
												<Text me='10px' color={textColor} fontSize='sm' fontWeight='700'>
													{cell.value}
												</Text>
											</Flex>
										);
									} else if (cell.column.Header === 'VALUE') {
										data = (
											<Text color={textColor} fontSize='sm' fontWeight='700'>
												${cell.value}
											</Text>
										);
									} else if (cell.column.Header === 'COUNTRY') {
										data = (
											<Text color={textColor} fontSize='sm' fontWeight='700'>
												{cell.value}
											</Text>
										);
									}
									else if (cell.column.Header === 'TYPE') {
										data = (
											<Text color={textColor} fontSize='sm' fontWeight='700'>
												{cell.value}
											</Text>
										);
									}
									else if (cell.column.Header === 'DELETE') {
					
										//console.log(original)
										data = (
											<Button variant='subtle' onClick={() => {
												setToDelete(row.original.api)
												setConfirm(true)
											} } >
												Delete
											</Button>
										);
									}
									return (
										<Td
											{...cell.getCellProps()}
											key={index}
											fontSize={{ sm: '14px' }}
											minW={{ sm: '150px', md: '200px', lg: 'auto' }}
											borderColor='transparent'>
											{data}
										</Td>
									);
								})}
							</Tr>
						);
					})}
				</Tbody>
			</Table>
			<Flex justifyContent="space-between" m={4} alignItems="center">
        		<Flex>
					<Tooltip label="Previous Page">
						<IconButton
							onClick={previousPage}
							isDisabled={!canPreviousPage}
							icon={<ChevronLeftIcon h={6} w={6} />} aria-label={''}				/>
					</Tooltip>
					<Tooltip label="Next Page">
						<IconButton
							onClick={nextPage}
							isDisabled={!canNextPage}
							icon={<ChevronRightIcon h={6} w={6} />} aria-label={''}				/>
					</Tooltip>
			  	</Flex>
			</Flex>
			<ConfirmDialog onClose={() => setConfirm(false)} open={confirm} accept={() => {
				deleteSelectedCoupon(toDelete)
				setConfirm(false)
				}} title={"Are you sure you want to delete this coupon"} desc={""} />
		</Card>
	);
}
