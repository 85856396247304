import { Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue,   
	Input,
	Checkbox,
	Button,
	InputGroup,
	InputLeftElement
} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

import { Accordion,  Box } from '@mantine/core';

import { Divider } from '@mantine/core';


// Custom components
import Card from 'components/card/Card';
import RTE from './RTE';

export default function PackageTable(props: { 
		columnsData: any; 
		tableData: any,
		removePackage: (index: number) => void, 
		setPackageDiscount: (value: string, index: number) => void
		setPackageBasePrice: (value: string, index: number) => void
		handleProductChange: (prop: string, index: number, value: any) => void
		country: string
		member_discount: number
	}) {
	const { columnsData, tableData, removePackage, setPackageDiscount, setPackageBasePrice, handleProductChange, country, member_discount} = props;

	const columns = useMemo(() => columnsData, [ columnsData ]);
	const data = useMemo(() => tableData, [ tableData ]);

	const tableInstance = useTable(
		{
			columns,
			data,
			initialState : { 
				pageSize: 100,
				hiddenColumns: ['scope']
			}
		},
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = tableInstance;

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
	return (
		<Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Flex justify='space-between' mb='20px' align='center'>
				<Text color={textColor} fontSize='19px' fontWeight='700' lineHeight='100%'>
					Packages
				</Text>
			</Flex>
			<Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
				<Thead>
					{headerGroups.map((headerGroup, index) => (
						<Tr {...headerGroup.getHeaderGroupProps()} key={index}>
							{headerGroup.headers.map((column, index) => (
								<Th
									{...column.getHeaderProps(column.getSortByToggleProps())}
									pe='10px'
									key={index}
									borderColor={borderColor}>
									<Flex
										justify='space-between'
										align='center'
										fontSize={{ sm: '10px', lg: '12px' }}
										color='gray.500'>
										{column.render('Header')}
									</Flex>
								</Th>
							))}
						</Tr>
					))}

				</Thead>
				{ tableData && 
				<Tbody {...getTableBodyProps()}>
					{page.map((row, index) => {
						prepareRow(row);
						//console.log(row.values.scope)
						return (
							<>

							<Tr {...row.getRowProps()} key={index} style={{
								borderTop: '2px solid #e2e8f0',
								borderRight: '2px solid #e2e8f0',
								borderLeft: '2px solid #e2e8f0',
							}}>
								{row.cells.map((cell, cell_index) => {
									let data;
									if (cell.column.Header === 'SKU') {
										return (
											<Td
											{...cell.getCellProps()}
											key={cell_index}
											fontSize={{ sm: '14px' }}
											minW={{ sm: '150px', md: '200px', lg: 'auto' }}
											borderColor='transparent'>
											<Flex align='center'>
												<Text me='10px' color={textColor} fontSize='sm' fontWeight='700'>
													{cell.value}
												</Text>
											</Flex>
										</Td>
										)

									} else if (cell.column.Header === 'NET PRICE') {
										return (
											<Td
											{...cell.getCellProps()}
											key={cell_index}
											fontSize={{ sm: '14px' }}
											minW={{ sm: '120px', md: '120px', lg: '120px' }}
											borderColor='transparent'>
											<Text color={textColor} fontSize='sm' fontWeight='700'>
												{country === "AU" ? '$' : '£'}{(parseFloat(row.values.base_price) - (parseFloat(row.values.base_price) * (member_discount / 100))) - parseFloat(row.values.discount)}
											</Text>
										</Td>
										)
									} else if (cell.column.Header === 'BASE') {
										return (
											<Td
											{...cell.getCellProps()}
											key={cell_index}
											fontSize={{ sm: '14px' }}
											minW={{ sm: '150px', md: '150px', lg: '150px' }}
											borderColor='transparent'>
												<InputGroup>
													<InputLeftElement
													pointerEvents='none'
													color='gray.300'
													fontSize='1.2em'
													maxW={{ sm: '140px', md: '140px', lg: '140px' }}
													children={country === "AU" ? '$' : '£'}
													/>
													<Input 
														type="number" 
														onChange={(event) => setPackageBasePrice(event.target.value, index)} 
														onWheel={ event => event.currentTarget.blur() }
														value={cell.value} 
													/>
												</InputGroup>
											</Td>
										)
										} else if (cell.column.Header === 'MEMBER DISC') {
										return (
											<Td
											{...cell.getCellProps()}
											key={cell_index}
											fontSize={{ sm: '14px' }}
											minW={{ sm: '100px', md: '150px' }}
											borderColor='transparent'>
												{country === "AU" ? '$' : '£'}{parseFloat(row.values.base_price) * (member_discount / 100)}
											</Td>
										)
									}  else if (cell.column.Header === 'MANUAL DISC') {
										return (
											<Td
											{...cell.getCellProps()}
											key={cell_index}
											fontSize={{ sm: '14px' }}
											minW={{ sm: '100px', md: '150px' }}
											borderColor='transparent'>
												<InputGroup>
													<InputLeftElement
													pointerEvents='none'
													color='gray.300'
													fontSize='1.2em'
													children={country === "AU" ? '$' : '£'}
													/>
													<Input 
														type="number" 
														onWheel={ event => event.currentTarget.blur() }
														onChange={(event) => setPackageDiscount(event.target.value, index)} 
														value={cell.value}
													/>
												</InputGroup>
											</Td>
										)
									} else if (cell.column.Header === 'OPTIONAL') {
										return (
											<Td
											{...cell.getCellProps()}
											key={cell_index}
											fontSize={{ sm: '14px' }}
											minW={{ sm: '150px', md: '200px', lg: 'auto' }}
											borderColor='transparent'>
												<Checkbox onChange={(event) => handleProductChange("optional", index, event.target.checked )}/>
											</Td>
										)
									} else if (cell.column.Header === 'TAX FREE') {
										return (
											<Td
											{...cell.getCellProps()}
											key={cell_index}
											fontSize={{ sm: '14px' }}
											minW={{ sm: '150px', md: '200px', lg: 'auto' }}
											borderColor='transparent'>
												<Checkbox onChange={(event) => handleProductChange("gst_free", index, event.target.checked )}/>
											</Td>
										)
									}
									return (
										<Td
											{...cell.getCellProps()}
											key={cell_index}
											fontSize={{ sm: '14px' }}
											minW={{ sm: '150px', md: '200px', lg: 'auto' }}
											borderColor='transparent'>
											{data}
										</Td>
									);
								})}
									<Td
											key={index}
											fontSize={{ sm: '14px' }}
											minW={{ sm: '150px', md: '200px', lg: 'auto' }}
											borderColor='transparent'>
											<Button onClick={() => removePackage(index)}>Delete</Button>
										</Td>

							</Tr>
							<Tr style={{
								borderRight: '2px solid #e2e8f0',
								borderLeft: '2px solid #e2e8f0',
							}}>
								<Td
									colSpan={9}
									key={"name-row-"+index}
									fontSize={{ sm: '14px' }}
									minW={{ sm: '150px', md: '200px', lg: '390px' }}
									borderColor='transparent'>
									<Flex align='center'>
										<Text style={{
											padding: ' 0 30px 0 25px',
											fontFamily: 'Segoe UI',
											fontWeight: 700,
											fontSize: '12px',
											color: "#a0aec0"
										}}>
											NAME
										</Text>
										<Input onChange={(event) => handleProductChange("name", index, event.target.value)} key={"name-input-"+index} value={row.values.name} />
									</Flex>
								</Td>
							</Tr>
							<Tr style={{
								borderBottom: '2px solid #e2e8f0',
								borderRight: '2px solid #e2e8f0',
								borderLeft: '2px solid #e2e8f0',
							}}>
								<Td colSpan={9} key={index + "-scope"}>
									<Accordion>
									<Accordion.Item value={index+'-scope-accordion'}  
											style={{
												border: "none",
												backgroundColor: "#fafafa",
												
											}}>
											<Accordion.Control>
												<Box>
													<Text style={{
														padding: '10px',
														fontFamily: 'Segoe UI',
														fontWeight: 700,
														fontSize: '12px',
														color: "#a0aec0"
													}}>
														SCOPE
													</Text>
												</Box>
											</Accordion.Control>
											<Accordion.Panel pb={4}>
												<RTE value={row.values.scope} onChange={(value) => handleProductChange("scope", index, value)} id={"package"+index} />
											</Accordion.Panel>
										</Accordion.Item>
									</Accordion>
								</Td>
							</Tr>	

						</>
						);
					})}

				</Tbody>
				}
			</Table>
		</Card>
	);
}
