/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSLProposals = /* GraphQL */ `
  mutation CreateSLProposals($input: CreateSLProposalsInput!) {
    createSLProposals(input: $input) {
      id
      client_name
      client_number
      company_name
      company_description
      company_number
      additional_notes
      country
      createdAt
      paidAt
      deal_id
      delivery_date
      expiryDate
      proposal_brief
      status
      member_upsell
      show_member_upsell
      add_membership
      membership_price
      membership_discount
      membership_discount_percentage
      already_member
      value
      searchquery
      preselected_packages
      customer_id
      products {
        name
        price
        discount
        description
        summary
        government_fee
        selected
        optional
        gst_free
        link
        SKU
        searchquery
        pipedrive
      }
      conflict_search
      short_address
      express_delivery_date
      purchased_membership
      creator
      email
      phone
      lawyer_notes
      calendly_link
      manual_allocation
      industry
      email_links
      specific_lawyer
      booked
      lpm_notes
      modifiedAt
      calendly_message
      calendly_date
      project_name
      address_line_1
      address_line_2
      city
      state
      postcode
      country_code
      formatted_address
    }
  }
`;
export const updateSLProposals = /* GraphQL */ `
  mutation UpdateSLProposals($input: UpdateSLProposalsInput!) {
    updateSLProposals(input: $input) {
      id
      client_name
      client_number
      company_name
      company_description
      company_number
      additional_notes
      country
      createdAt
      paidAt
      deal_id
      delivery_date
      expiryDate
      proposal_brief
      status
      member_upsell
      show_member_upsell
      add_membership
      membership_price
      membership_discount
      membership_discount_percentage
      already_member
      value
      searchquery
      preselected_packages
      customer_id
      products {
        name
        price
        discount
        description
        summary
        government_fee
        selected
        optional
        gst_free
        link
        SKU
        searchquery
        pipedrive
      }
      conflict_search
      short_address
      express_delivery_date
      purchased_membership
      creator
      email
      phone
      lawyer_notes
      calendly_link
      manual_allocation
      industry
      email_links
      specific_lawyer
      booked
      lpm_notes
      modifiedAt
      calendly_message
      calendly_date
      project_name
      address_line_1
      address_line_2
      city
      state
      postcode
      country_code
      formatted_address
    }
  }
`;
export const deleteSLProposals = /* GraphQL */ `
  mutation DeleteSLProposals($input: DeleteSLProposalsInput!) {
    deleteSLProposals(input: $input) {
      id
      client_name
      client_number
      company_name
      company_description
      company_number
      additional_notes
      country
      createdAt
      paidAt
      deal_id
      delivery_date
      expiryDate
      proposal_brief
      status
      member_upsell
      show_member_upsell
      add_membership
      membership_price
      membership_discount
      membership_discount_percentage
      already_member
      value
      searchquery
      preselected_packages
      customer_id
      products {
        name
        price
        discount
        description
        summary
        government_fee
        selected
        optional
        gst_free
        link
        SKU
        searchquery
        pipedrive
      }
      conflict_search
      short_address
      express_delivery_date
      purchased_membership
      creator
      email
      phone
      lawyer_notes
      calendly_link
      manual_allocation
      industry
      email_links
      specific_lawyer
      booked
      lpm_notes
      modifiedAt
      calendly_message
      calendly_date
      project_name
      address_line_1
      address_line_2
      city
      state
      postcode
      country_code
      formatted_address
    }
  }
`;
export const createSLProducts = /* GraphQL */ `
  mutation CreateSLProducts($input: CreateSLProductsInput!) {
    createSLProducts(input: $input) {
      SKU
      country
      package_name
      discount
      price
      scope
      summary
      stacker
      gov_fees
      searchquery
      optional
      gst_free
      link
      pipedrive
    }
  }
`;
export const updateSLProducts = /* GraphQL */ `
  mutation UpdateSLProducts($input: UpdateSLProductsInput!) {
    updateSLProducts(input: $input) {
      SKU
      country
      package_name
      discount
      price
      scope
      summary
      stacker
      gov_fees
      searchquery
      optional
      gst_free
      link
      pipedrive
    }
  }
`;
export const deleteSLProducts = /* GraphQL */ `
  mutation DeleteSLProducts($input: DeleteSLProductsInput!) {
    deleteSLProducts(input: $input) {
      SKU
      country
      package_name
      discount
      price
      scope
      summary
      stacker
      gov_fees
      searchquery
      optional
      gst_free
      link
      pipedrive
    }
  }
`;
