/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, SimpleGrid } from "@chakra-ui/react";

import DiscountTable from "views/admin/discount/components/DiscountTable";

import { useEffect, useState} from "react";

import { getCoupons } from "../../../services/APIService";
import { discountColumns } from "./variables/tableColumns";
import DiscountCreationDialog from "./components/DiscountCreator";

import { ICoupon } from "coupons";

import { deleteCoupon } from "../../../services/APIService";


function createRow(name : string, api : string, type : string, price : string, country : string) : ICoupon {
    return {
      name,
      api,
      type,
      price,
      country
    };
}

export default function Settings() {
    const [data, setData] = useState<Array<ICoupon>>();
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);


    const addRow = (name : string, id : string, type : string, value : string, country : string) => {
        setData([...data!, createRow(name, id, type, value, country)]);
        setOpen(false);
    }

    async function deleteSelectedCoupon(id : string, index : number) {
        var array = [...data!]; // make a separate copy of the array
        console.log(id, index)
        await deleteCoupon(id);

        if (index !== -1) {
          array.splice(index, 1);
          setData(array);
        }


    }

    useEffect(() => {


        async function setup()
        {

            var rows = [];
            var coupons = await getCoupons();

            var items = coupons.Items;
            for(var i = 0; i < items.length; i++)
            {
                rows.push(createRow(items[i].coupon_name, items[i].coupon_id, items[i].coupon_type, items[i].coupon_value, items[i].country))
            }

            console.log(rows)
            setData(rows)
            setLoading(false)
        }
        setup();
        
      }, []);


    // Chakra Color Mode
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <SimpleGrid
                mb='20px'
                columns={{ sm: 1, md: 1 }}
                spacing={{ base: "20px", xl: "20px" }}>
                {!loading && <DiscountTable tableData={data} columnsData={discountColumns} openCreator={() => setOpen(true)} deleteSelectedCoupon={deleteSelectedCoupon} />}

            </SimpleGrid>

            <DiscountCreationDialog addRow={addRow} open={open} onClose={() => setOpen(false)} />
        </Box>
    );
}
