import { Icon } from '@chakra-ui/react';
import { MdBarChart, MdPerson, MdHome, MdLock, MdOutlineShoppingCart } from 'react-icons/md';
import { AiFillTags } from 'react-icons/ai';
import { HiOutlineDocumentDuplicate } from 'react-icons/hi';

// Admin Imports
import MainDashboard from 'views/admin/default';
import Discount from 'views/admin/discount';
import Profile from 'views/admin/profile';
import DataTables from 'views/admin/dataTables';
import RTL from 'views/admin/rtl';

// Auth Imports
import SignInCentered from 'views/auth/signIn';
import Proposal from 'views/admin/proposal';
import SubscriptionGenerator from 'views/admin/subGen';
import ProposalCreator from 'views/admin/proposalCreator';
import ProposalEditor from 'views/admin/proposalEditor';

const routes = [
	{
		name: 'Sub Generator',
		layout: '/admin',
		path: '/subgen',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		display: true,
		component: SubscriptionGenerator
	},
	{
		name: 'Discounts',
		layout: '/admin',
		icon: <Icon as={AiFillTags} width='20px' height='20px' color='inherit' />,
		path: '/discounts',
		display: true,
		component: Discount
	},
	{
		name: 'Proposals',
		layout: '/admin',
		icon: <Icon as={HiOutlineDocumentDuplicate} width='20px' height='20px' color='inherit' />,
		path: '/proposals',
		display: true,
		component: Proposal
	},
	{
		name: 'Create Proposal',
		layout: '/admin',
		icon: <Icon as={HiOutlineDocumentDuplicate} width='20px' height='20px' color='inherit' />,
		path: '/proposals/create',
		display: true,
		component: ProposalCreator
	},
	{
		name: 'Create Proposal',
		layout: '/admin',
		icon: <Icon as={HiOutlineDocumentDuplicate} width='20px' height='20px' color='inherit' />,
		path: '/proposals/create/:id',
		display: false,
		component: ProposalCreator
	},
	{
		name: 'Edit Proposal',
		layout: '/admin',
		icon: <Icon as={HiOutlineDocumentDuplicate} width='20px' height='20px' color='inherit' />,
		path: '/proposals/edit/:id',
		display: false,
		component: ProposalEditor
	},
	{
		name: 'Sign In',
		layout: '/auth',
		path: '/sign-in',
		icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
		display: false,
		component: SignInCentered
	},
];

export default routes;
