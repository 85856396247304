/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, Checkbox } from "@chakra-ui/react";


import {useEffect, useMemo, useState} from "react";

import { createMembershipLink, getCoupons } from "../../../services/APIService";

import { 
    FormControl,
    Input,
    Select,
    Stack ,
    Button,
    Text
} from '@chakra-ui/react'
import { useLocation } from "react-router-dom";
import { ICoupon } from "coupons";

import Card from 'components/card/Card';

import LinkPage from "./components/LinkPage";
import styled from "styled-components";


const FieldHeader = styled(Text)`
    font-size: 1.0rem;
    font-weight: 600;
`

const HelperText = styled(Text)`
    font-size: 0.8rem;
    margin: 0 0 0 10px;
    color: #6b6b6b;
`

const Required = styled.span`
    color: red;
    content: "*";
    
`

const types= [
    {value: 'membership', name: "Membership" },
    {value: 'cosec', name: "CoSec"},
]

const categories = [
    {value: 'New Business', name: "New Business" },
    {value: 'Return Business', name: "Return Business" },
]

const countries = [
    {value: 'AU', name: "Australia" },
    {value: 'UK', name: "UK" },
]


function useQuery() {
    const { search } = useLocation();
  
    return useMemo(() => new URLSearchParams(search), [search]);
}

var au_discounts : ICoupon[] = [];
var uk_discounts : ICoupon[] = [];


export default function SubscriptionGenerator() {
  
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    let query = useQuery();

    const [noDeal, setNoDeal] = useState(false);
    const [subtitle, setSubtitle] = useState('');
    const [discount, setDiscount] = useState('intro_discount');
    const [type, setType] = useState('membership');
    const [category, setCategory] = useState('New Business')
    const [data, setData] = useState(null);
    const [country, setCountry] = useState("AU")

    const [deal, setDeal] = useState(query.get("deal") === null ? query.get("selectedIds") : query.get("deal"));


    useEffect(() => {


        async function fetchCoupons(){
            setLoading(true);
            uk_discounts = [];
            au_discounts = [];
            
            //console.log(query.get("deal"))
            let c = await getCoupons(); 
            for(let i = 0; i < c.Items.length; i++){
                let item = c.Items[i];
                switch(item.country){
                    case 'AU': 
                        au_discounts.push({api: item.coupon_id, name: item.coupon_name, type: item.coupon_type, price: item.coupon_value, country: item.country});  
                        break;
                    case 'UK':
                        uk_discounts.push({api: item.coupon_id, name: item.coupon_name, type: item.coupon_type, price: item.coupon_value, country: item.country}); 
                        break;
                }

            }
            setLoading(false);
        }
        fetchCoupons();
        
      }, []);

    const handleDiscount = (event : React.ChangeEvent<HTMLSelectElement>) => {
        setDiscount(event.target.value);
    };
    

    const handleType = (event : React.ChangeEvent<HTMLSelectElement>) => {
        setType(event.target.value);
        if(event.target.value === "cosec"){
            setDiscount("full_price_cosec_au");
        }
        if(event.target.value === "membership"){
            setDiscount("intro_discount")
        }
    }

    const handleCategory = (event : React.ChangeEvent<HTMLSelectElement>) => {
        setCategory(event.target.value);
    }

    const handleCountry = (event : React.ChangeEvent<HTMLSelectElement>) => {
        setCountry(event.target.value);
        switch(event.target.value){
            case 'AU': 
                setDiscount("intro_discount");
                break;
            case 'UK':
                setDiscount("full_price_membership_uk")
                break;
        }
    }

    const handleSubmit = async (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);

        let amount = au_discounts[0].price;
        
        if(country === "AU"){
            let result = au_discounts.filter(obj => {
                return obj.api === discount
              })
    
            amount = result[0].price;
        }

        if(country === "UK"){
            let result = uk_discounts.filter(obj => {
                return obj.api === discount
              })
    
            amount = result[0].price;
        }



        if(deal === "" || deal === null){
            if(noDeal){
                console.log("No Deal")
            }else {
                setError(true);
                setLoading(false);
                return;
            }
        }

        console.log(deal, type, discount, category, amount, country, noDeal, subtitle)

        var link = await createMembershipLink(noDeal ? "NO_DEAL" : deal, type, discount, category, amount, country, noDeal, subtitle);
        console.log(link)
        setData(link);
        setLoading(false);
    }
    

    // Chakra Color Mode
    return !data ? (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <Card flexDirection='column' w='100%' px='40px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
                <form  onSubmit={handleSubmit}>
                    <FormControl>
                        <Stack spacing={3}>
                            
                            
                            {!noDeal && 
                            <>
                                <Text >Deal ID</Text>
                                <Input
                                    placeholder="Deal ID" 
                                    name="deal" 
                                    onChange={(event) => setDeal(event.target.value)}
                                    type="text"
                                    value={deal}
                                    size='md'
                                    variant='outline'
                                />
                            </>}


                            <HelperText></HelperText>
                            <Checkbox 
                                isChecked={noDeal} 
                                onChange={(e) => setNoDeal(e.target.checked)} 
                            >
                                No Deal Signup
                            </Checkbox>

                            {noDeal && 
                            <>
                                <Text >Subtitle Text</Text>
                                <Input
                                    placeholder="Subtitle Text" 
                                    name="subtitle_text" 
                                    onChange={(event) => setSubtitle(event.target.value)}
                                    type="text"
                                    value={subtitle}
                                    size='md'
                                    variant='outline'
                                />
                            </>}


                            <Text>Type</Text>
                            <Select
                                placeholder="Type"
                                value={type}
                                name="type"
                                onChange={handleType}
                                
                            >
                                {types.map(item => (
                                    <option value={item.value} key={item.value}>{item.name}</option >
                                ))}
                            </Select>

                            <Text >Category</Text>
                            <Select
                                placeholder="Category"
                                value={category}
                                name="category"
                                onChange={handleCategory}
                                
                            >
                                {categories.map(item => (
                                    <option value={item.value} key={item.value}>{item.name}</option>
                                ))}
                            </Select>




                            <Text >Country</Text>
                            <Select
                                placeholder="Country"
                                value={country}
                                name="country"
                                onChange={handleCountry}
                                
                            >
                                {countries.map(item => (
                                    <option value={item.value} key={item.value}>{item.name}</option>
                                ))}
                            </Select>

                            <Text >Discount</Text>
                            <Select
                                placeholder="Discount"
                                value={discount}
                                name="discount"
                                onChange={handleDiscount}
                                
                            >
                                { country === "AU" ? 
                                au_discounts.map(item => {
                                    return item.type === type || item.type === "both" ? (
                                        <option value={item.api} key={item.api}>{item.name} - ${item.price}</option>
                                    ) : (null)
                                })
                                :
                                uk_discounts.map(item => {
                                    return item.type === type || item.type === "both" ? (
                                        <option value={item.api} key={item.api}>{item.name} - £{item.price}</option>
                                    ) : (null)
                                })
                                }
                            </Select>
                            <Button colorScheme='blue' type="submit" sx={{ m: 2 }} isLoading={loading} loadingText='Submitting'>Submit</Button>

                        </Stack>
                    </FormControl>
                </form>
            </Card>
        </Box>
    ) : 
    (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <Card flexDirection='column' w='100%' px='40px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
                <LinkPage data={data} type={type}/>
            </Card>
        </Box>
    )
}
