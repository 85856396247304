import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/App.css';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';

import { ThemeProvider, createTheme} from "@mui/material/styles";


import { ProtectedRoute } from 'components/routes/ProtectedRoute';

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import { AuthProvider } from 'hooks/AuthContext';

const isLocalhost = Boolean(
	window.location.hostname === "localhost" ||
	  // [::1] is the IPv6 localhost address.
	  window.location.hostname === "[::1]" ||
	  // 127.0.0.1/8 is considered localhost for IPv4.
	  window.location.hostname.match(
		/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
	  )
  );
  
  // Assuming you have two redirect URIs, and the first is for localhost and second is for production
  const [
	localRedirectSignIn,
	productionRedirectSignIn,
  ] = awsconfig.oauth.redirectSignIn.split(",");
  
  const [
	localRedirectSignOut,
	productionRedirectSignOut,
  ] = awsconfig.oauth.redirectSignOut.split(",");
  
  const updatedAwsConfig = {
	...awsconfig,
	oauth: {
	  ...awsconfig.oauth,
	  redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
	  redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
	}
  }
  
  Amplify.configure(updatedAwsConfig);
  

const mui_theme = createTheme({

});

  
ReactDOM.render(
	
	<ChakraProvider theme={theme}>

			<HashRouter>
				<AuthProvider>
					<Switch>
						<Route path={`/auth`} component={AuthLayout} />
						<ProtectedRoute path={`/admin`} component={AdminLayout} />
						<Redirect from='/' to='/admin' />
					</Switch>
				</AuthProvider>
			</HashRouter>

	</ChakraProvider>,
	document.getElementById('root')
);
