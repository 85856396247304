

import { 
  Button,
  Tooltip
} from '@chakra-ui/react'

import { useState } from "react";

interface ILinkProps {
    data: {
        link: string
        pipe: string
    }
    type: string

}

export default function LinkPage(props : ILinkProps){
    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
      setOpen(false);
    };

    return (
    <span>
      <div className="sr-title thankyou-title">

      <div className="thankyou-tick"/>
      <p>Link successfully generated (Click to add to clipboard):</p>
        <Tooltip
                label="Link added to clipboard"
                isOpen={open}
              >
            <Button variant="text" onClick={()=> {
                navigator.clipboard.writeText(props.data.link)
                setOpen(true);

                setTimeout(() => {
                  setOpen(false)
                }, 1000);
              }}
              style={{
                  margin: '5px auto',
                  width: '100%'
              }}>
                {props.data.link}
            </Button>
        </Tooltip>
      <p>There is a new {props.type} deal being created on the {props.data.pipe} pipe. </p>
      </div>
    </span>
    )
}